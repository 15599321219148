// @ts-check
import m from 'mithril';
import ReportFactory from './report_factory';
// import dayjs from 'dayjs';

import MappeData from './mappe_data';
import GemeindeInfo from './gemeinde_info';
import LiedInfo from './lied_info';
import VortragInfo from './vortrag_info';

import dayjs from 'dayjs';
// für lokalisierte Version window.dayjs verwenden!

/**
 * @typedef {Object<string, number | string>} PdfCmd
 */

const ListenView = () => {
    let verzeichnisPfad = localStorage.getItem('mappe2024_verzeichnis') ?? "Y:\\Documents\\Mappe2024_Reports";
    /** @type {MappeData} */
    let mpData;
    
    /** @type {(msg: string, type?: 'success' | 'warning' | 'error' | undefined, seconds?: number | undefined) => void} */
    let setMsg;

    const MAPPE_TOKEN = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJiZWF0IiwiYXVkIjoiaHR0cDovL2RhdGFzdG9yZS53aWJlNDE1My5jaCIsInNjb3BlcyI6WyJ1c2VyIl0sImlzcyI6Imh0dHA6Ly93aWJlNDE1My5jaCIsImlhdCI6MTcwNjA4Njc1MiwibmJmIjoxNzA2MDg2NzUyLCJleHAiOjE3MDYxNzMxNTJ9.8InfMNIptpCFF41zTySaBYzkmucsYMPgWd8Peb2_lMI";

    let showLink = false;
    let reportLink = "";
    let repHitsSeit = "";

    function oninit(vnode) {
        ({ mpData, setMsg } = vnode.attrs);
        repHitsSeit = "01.10.2000";
    }

    /**
     * @param {{ target: { id: any; }; }} e
     */
    async function handleClick(e) {
        /** @type {PdfCmd[] | {}} */
        let json = [];

        let repTitle = "mappe2024_report.pdf";
        const rep = ReportFactory();
        
        /** @type {Object[]} */
        let selItems;
                        
        // @ts-ignore
        let stichDatum = window.dayjs();
        switch (e.target.id) {
            case 'vortraege_next':                
                const wtGD = mpData.gemeinde.wtgd - 1; 
                // wtGD-1: 0=Sunday, 1=Monday, 2=Tuesday, 3=Wednesday, 4=Thursday, 5=Friday, 6=Saturday
                // dayjs.day(): numbers from 0 (Sunday) to 6 (Saturday)
                while (stichDatum.day() != 0 && stichDatum.day() != wtGD) {  
                    stichDatum = stichDatum.add(1, 'day');
                }
                /** @type {LiedInfo[]} */
                selItems = filterLiederNextVortrag([ ...mpData.lieder.values() ], stichDatum, mpData.gemeinde);
                selItems.sort((a, b) => a.id - b.id);
                repTitle = "Vorträge für " + stichDatum.format("dd. DD.MM.YYYY");
                json = rep.createReport(repTitle, selItems, rep.Template.Lieder, mpData);
                break;

            case 'vortraege_last':                
                /** @type {VortragInfo[]} */
                selItems = [ ...mpData.vortraege.values()];
                selItems.sort((a, b) => {
                    let res = b.datum.localeCompare(a.datum);
                    if (res == 0) {
                        res = b.id - a.id;
                    }
                    return res;
                });
                selItems = selItems.slice(0, 120)
                repTitle = "Letzte Vorträge";
                json = rep.createReport(repTitle, selItems, rep.Template.Vortraege, mpData);
                break;

            case 'vortraege_hits':
                stichDatum = dayjs(mpData.getSqlDate(repHitsSeit) + " 00:00:00");                
                for (const [key, vortrag] of mpData.vortraege) {
                    vortrag.getLied().hits = 0;
                }
                for (const [key, vortrag] of mpData.vortraege) {                    
                    if (vortrag.datum < stichDatum.format("YYYY-MM-DD"))
                        continue;
                    let lied = vortrag.getLied();
                    lied.hits += 1;                                        
                }                
                let items = [...mpData.lieder.values()].filter((/** @type {LiedInfo} */lied) => lied.hits > 0);
                items.sort((a, b) => { 
                    let res = b.hits - a.hits;
                    if (res == 0) {
                        res = a.id - b.id;
                    }
                    return b.hits - a.hits
                });        
                repTitle = `Hitliste (seit ${repHitsSeit})`;
                json = rep.createReport(repTitle, items, rep.Template.LiederHits, mpData);
                break;

            case 'proben_next':                
                let wtProbe = mpData.gemeinde.wtprobe - 1; // 0=Sunday, 1=Monday, 2=Tuesday, 3=Wednesday, 4=Thursday, 5=Friday, 6=Saturday
                // dayjs.day(): numbers from 0 (Sunday) to 6 (Saturday)
                while (stichDatum.day() != wtProbe) {  
                    stichDatum = stichDatum.add(1, 'day');
                }
                selItems = filterLiederNextProbe([ ...mpData.lieder.values() ], stichDatum, mpData.gemeinde);
                selItems.sort((a, b) => a.id - b.id);
                repTitle = "Proben für " + stichDatum.format("dd. DD.MM.YYYY");
                json = rep.createReport(repTitle, selItems, rep.Template.Lieder, mpData);
                break;

            case 'proben_last':                
                selItems = [ ...mpData.proben.values() ];
                selItems.sort((a, b) => {
                    let res = b.datum.localeCompare(a.datum);
                    if (res == 0) {
                        res = b.id - a.id;
                    }
                    return res;
                });
                selItems = selItems.slice(0, 120);
                repTitle = "Letzte Proben";
                json = rep.createReport(repTitle, selItems, rep.Template.Proben, mpData);
                break;

            case 'lieder_nummern':
                selItems = [ ...mpData.lieder.values() ];
                selItems.sort((a, b) => a.id - b.id);
                repTitle = "Lieder nach Nummern";
                json = rep.createReport(repTitle, selItems, rep.Template.LiederNummern, mpData);
                break;
            case 'lieder_alpha':
                selItems = [ ...mpData.lieder.values() ];
                selItems.sort((a, b) => {
                    const res = a.text.localeCompare(b.text);
                    return res == 0 ? a.id - b.id : res;
                });
                repTitle = "Lieder alphabetisch";
                json = rep.createReport(repTitle, selItems, rep.Template.LiederAlpha, mpData);
                break;
            case 'lieder_spez':
                selItems = [ ...mpData.lieder.values() ].filter((lied) => (lied.status === 'S'));
                selItems.sort((a, b) => a.id - b.id);
                repTitle = "Speziallieder";
                json = rep.createReport(repTitle, selItems, rep.Template.LiederNummern, mpData);
                break;
        }
        if (json) {
            setMsg("Bitte warten, erstelle PDF-Datei...", "warning");
            // var url = "http://localhost/pdfgen/api.php";
            var url = "https://wibe4153.ch/pdfgen/api.php";
            try {
                await m.request({
                    method: 'POST',
                    headers: { 'Authorization': 'Bearer ' + MAPPE_TOKEN, 'Content-Type': 'application/json' },
                    url,
                    body: json
                });
                if (window.isSecureContext && navigator.clipboard && navigator.clipboard.writeText) {
                    await navigator.clipboard.writeText(verzeichnisPfad + "\\" + repTitle + ".pdf");
                }
                window.open(url.replace('/api.php', '') + "/mappe2024/" + repTitle + ".pdf", '_blank');
                setMsg("");
            } catch (error) {
                setMsg("Fehler beim Erstellen der PDF-Datei", error, 0);
            }
        }
    }

    /**
     * @param {LiedInfo[]} lieder
     * @param {any} stichdatum
     * @param {GemeindeInfo} gde
     */
    function filterLiederNextProbe(lieder, stichdatum, gde) {
        const VortrVor = stichdatum.subtract(gde.sperrfrist, 'day').format('YYYY-MM-DD');
        const RProbeVor = stichdatum.subtract(gde.rprobeintervall, 'day').format('YYYY-MM-DD');
        const PProbeVor = stichdatum.subtract(gde.pseitprobe, 'day').format('YYYY-MM-DD');

        const result = [];
        lieder.forEach(lied => {
            let fnd = false;
            if (lied.lastprobe > 0 || lied.lastvortrag > 0) {
                let lastVortrag = mpData.getLastVortragAttrs(lied.lastvortrag);
                let lastProbe = mpData.getProbeAttrs(lied.lastprobe);
                switch (lied.status) {
                    case "U":
                        break;
                    case "N":
                        fnd = (lastProbe.result === "W");
                        break;
                    case "S":
                        if (lastVortrag.datum && lastVortrag.datum >= VortrVor) break;
                        fnd = gde.sshow && (lastProbe.result === "W" || (!!lastProbe.datum && lastProbe.datum < PProbeVor));
                        break;
                    case "P":
                        if (lastVortrag.datum && lastVortrag.datum >= VortrVor) break;
                        fnd = (lastProbe.result == "W") || (!!lastProbe.datum && (lastProbe.datum < PProbeVor));
                        break;
                    case "R":
                        if (lastVortrag.datum && lastVortrag.datum >= VortrVor) break;
                        fnd = (lastProbe.datum && lastProbe.datum < RProbeVor) || (lastProbe.result === "W");
                        break;
                }
            }
            if (fnd) {
                result.push(lied);
            }
        });
        result.sort((x, y) => x.Id - y.Id);
        return result;
    }

    /**
     * @param {LiedInfo[]} lieder
     * @param {any} stichdatum
     * @param {GemeindeInfo} gde
     */
    function filterLiederNextVortrag(lieder, stichdatum, gde) {
        const VortrVor = stichdatum.subtract(gde.sperrfrist, 'day').format('YYYY-MM-DD');
        // const RProbeVor = stichdatum.subtract(gde.rprobeintervall, 'day').format('YYYY-MM-DD');
        const PProbeVor = stichdatum.subtract(gde.pseitprobe, 'day').format('YYYY-MM-DD');

        const result = [];
        lieder.forEach(lied => {
            let fnd = false;
            if (lied.lastprobe > 0 || lied.lastvortrag > 0) {
                let lastVortrag = mpData.getLastVortragAttrs(lied.lastvortrag);
                let lastProbe = mpData.getProbeAttrs(lied.lastprobe);
                switch (lied.status) {
                    case "U":
                        break;
                    case "N":
                        fnd = (lastProbe.result === "V") && !!lastProbe.datum && lastProbe.datum >= PProbeVor;
                        break;
                    case "S":
                        if (lastVortrag.datum && lastVortrag.datum >= VortrVor) break;
                        fnd = gde.sshow && (lastProbe.result === "V" || (!!lastProbe.datum && lastProbe.datum >= PProbeVor));
                        break;
                    case "P":
                        if (lastVortrag.datum && lastVortrag.datum >= VortrVor) break;
                        fnd = (lastProbe.result === "V" && !!lastProbe.datum && lastProbe.datum >= PProbeVor);
                        break;
                    case "R":
                        if (!!lastVortrag.datum && lastVortrag.datum >= VortrVor) break;
                        fnd = (lastProbe.result !== "W");
                        break;
                }
            }
            if (fnd) {
                result.push(lied);
            }
        });
        result.sort((x, y) => x.Id - y.Id);
        return result;
    }

    /**
     * @param {{ value: string; }} e
     */
    function handleVerzeichnisChange(e) {
        verzeichnisPfad = e.value;
        localStorage.setItem('mappe2024_verzeichnis', verzeichnisPfad);
    }

    function view() {
        const isSmall = window.screen.availWidth <= 800;
        return m('div', { style: isSmall ? 'font-size: 0.8em;' : '' }, [
            m('h2', 'Listen'),

            m('h3', 'Vorträge'),
            m('div.container.ml-2', [
                m('div.row', m('div.col-3', m('a#vortraege_next', { href: '#', onclick: handleClick }, "Nächster Vortrag"))),
                m('div.row', m('div.col-3', m('a#vortraege_last', { href: '#', onclick: handleClick }, "Letzte Vorträge"))),
                m('div.row', [
                    m('div.col-3', m('a#vortraege_hits', { href: '#', onclick: handleClick }, "Hitliste")),
                    m('div.col-auto', m('label', { for: "rep-hits-seit" }, "seit:")),
                    m('div.col-2', m('input[type=text]#rep-hits-seit.wd-8', { value: repHitsSeit, onchange: (e) => repHitsSeit = e.target.value, placeholder: "01.01.2020" }))
                ])
            ]),

            m('h3', 'Proben'),
            m('div.container.ml-2', [
                m('div.row', m('div.col-3', m('a#proben_next', { href: '#', onclick: handleClick }, "Nächste Probe"))),
                m('div.row', m('div.col-3', m('a#proben_last', { href: '#', onclick: handleClick }, "Letzte Proben")))
            ]),

            m('h3', 'Lieder'),
            m('div.container.ml-2', [
                m('div.row', [
                    m('div.col-3', m('a#lieder_nummern', { href: '#', onclick: handleClick }, "Lieder nach Nummern")),
                    showLink && m('div.col-2', m('a', { href: reportLink, target: '_blank' }, "PDF-Datei")),
                ]),

                m('div.row', m('div.col-3', m('a#lieder_alpha', { href: '#', onclick: handleClick }, "Lieder alphabetisch"))),
                m('div.row', m('div.col-3', m('a#lieder_spez', { href: '#', onclick: handleClick }, "Speziallieder")))
            ]),

            m('div.row.mt-2',
                m('div.col-3 mt-2',
                    m('h4', "Verzeichnis")
                ),
                m('div.col-6.mt-2',
                    m('input[type=text]', { value: verzeichnisPfad, onchange: handleVerzeichnisChange })
                )
            ),
        ]);
    }

    return { oninit, view };
}

export { ListenView }
