// @ts-check
import m from 'mithril';
import { InputText, InputSelect, SaveCancel, ConfirmDialog } from './components';

import MappeData from './mappe_data';
import ProbeInfo from './probe_info';
import LiedInfo from './lied_info';
import VortragInfo from './vortrag_info';

const LiederView = () => {   
    /** @type {MappeData} */ 
    let mpData;

    /** @type {LiedInfo} */
    let editLied;

    /** @type {LiedInfo} */
    let emptyLied;

    let selectedId = 0;
    let goToSelectedId = 0;
    let sort = 0;    
    let showEditor = false;
    let showConfirm = false;

    const editorId = '#lied-editor';
    const confirmId = '#confirm-dialog';    

    const emptyFilter = {
        liedNr: '',
        text: '',
        status: '',
        lastProbe: '',
        lastVortrag: '',
    };
    let filter = { ...emptyFilter };
    

    /**
     * Initializes the component.
     *
     * @param {Object} vnode - The vnode parameter.
     */
    function oninit(vnode) {
        ({ mpData } = vnode.attrs);
        emptyLied = new LiedInfo(mpData)
    }

    function handleFilterChange(e) {
        const fld = e.target.id.slice(7);
        filter[fld] = (fld == "liedNr" || fld == "status") ? e.target.value.toUpperCase() : e.target.value;
        m.redraw();
    }

    function handleFilterReset() {
        filter = { ...emptyFilter };
        m.redraw();
    }

    function handleRowClick(e) {
        goToSelectedId = 0;
        selectedId = parseInt(e.currentTarget.id.slice(4));
    }

    function handleNewLied() {
        selectedId = 0;
        handleEditLied();
    }

    function handleEditLied() {
        editLied = (selectedId > 0 && mpData.lieder.has(selectedId)) ? /** @type {LiedInfo} */(mpData.lieder.get(selectedId)) : emptyLied;
        showEditor = true;
        setTimeout(() => {
            let dialog = document.querySelector(editorId);
            /** @type {HTMLDialogElement} */(dialog)?.showModal();
        }, 10);
    }

    function handleDeleteLied() {
        if (selectedId <= 0)
            return;
        showConfirm = true;
        setTimeout(() => {
            const dialog = document.querySelector(confirmId);
            /** @type {HTMLDialogElement} */(dialog)?.showModal();
        }, 10)
    }

    async function handleDeleteOk() {
        /** @type {LiedInfo | undefined} */
        const lied = mpData.lieder.get(selectedId);
        if (lied == undefined) return;
        const dialog = document.querySelector(confirmId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showConfirm = false;
        await mpData.deleteLied(lied);
    }

    function handleDeleteCancel() {
        const dialog = document.querySelector(confirmId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showConfirm = false;
    }

    async function handleEditorSave(lied) {
        const dialog = document.querySelector(editorId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showEditor = false;
        await mpData.saveLied(lied, editLied);
        goToSelectedId = lied.id;
    }

    function handleEditorCancel() {
        const dialog = document.querySelector(editorId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showEditor = false;
    }

    /**
     * Normalize the displayed lied number.
     *
     * @param {string} dispLiedNr - The displayed lied number to be normalized.
     * @return {string} - The normalized lied number.
     */

    function normalizeDispLiedNr(dispLiedNr) {
        return dispLiedNr.replace(/\s/g, '').toUpperCase();
    }

    /** 
     * @returns {LiedInfo[]}
     */
    function getDispLieder() {
        const normalizedFilterLiedNr = normalizeDispLiedNr(filter.liedNr);
        return [ ...mpData.lieder.values() ].filter((/** @type {LiedInfo} */ lied) => {
            if (normalizedFilterLiedNr) {
                const dispLiedNr = mpData.getDispLiedNr(lied.id);
                if (!normalizeDispLiedNr(dispLiedNr).includes(normalizedFilterLiedNr)) {
                    return false;
                }
            }
            if (filter.text && !lied.text.toLowerCase().includes(filter.text.toLowerCase())) {
                return false;
            }
            if (filter.status && !filter.status.toUpperCase().includes(lied.status)) {
                return false;
            }
            if (filter.lastProbe) {                
                const probe = mpData.getProbeAttrs(lied.lastprobe); // { datum: '', dispDatum: '', dirigent: '', result: '', liedid: 0 }
                if (probe.liedid == 0)
                    return false;
                if (!probe.getDispDatum().includes(filter.lastProbe)) {
                    return false;
                }
            }
            if (filter.lastVortrag) {                
                const vortrag = mpData.getVortragAttrs(lied.lastvortrag);
                if (vortrag.liedid == 0)
                    return false;
                if (!vortrag.getDispDatum().includes(filter.lastVortrag)) {
                    return false;
                }
            }
            return true;
        });
    }

    function handleGoTop() {
        const divTableContainer = document.getElementById('table-container');
        if (divTableContainer)
            divTableContainer.scrollTop = 0;
    }

    function view(vnode) {
        const mpData = vnode.attrs.mpData;
        /** @type {Map<number, LiedInfo>} */
        let lieder;
        /** @type {Map<number, ProbeInfo>} */
        let proben;
        /** @type {Map<number, VortragInfo>} */
        let vortraege;
        ({ lieder, proben, vortraege } = mpData);
        if (!lieder) return null;
        
        const dispLieder = getDispLieder();
        if (goToSelectedId > 0 && goToSelectedId != selectedId) {
            selectedId = goToSelectedId;
            setTimeout(() => {
                const el = document.getElementById(`row_${goToSelectedId}`);
                if (el) {
                    el.scrollIntoView();
                }
                goToSelectedId = 0;
            }, 100);
        }
        // @ts-ignore
        dispLieder.sort((a, b) => {
            /** @type {number | undefined} */
            let res;            
            let /** @type {string} */ datumA, /** @type {string} */ datumB, /** @type {string} */ lastProbeA, /** @type {string} */ lastProbeB;
            switch (sort) {
                case 0:
                    return a.id - b.id;
                case 1:
                    return a.text.localeCompare(b.text);
                case 2:
                    res = a.status.localeCompare(b.status);
                    return res == 0 ? a.id - b.id : res;
                case 3:
                    /** @type {string} */
                    datumA = a.lastprobe > 0 ? (/** @type {VortragInfo} */(vortraege.get(a.lastprobe)).datum) : "";
                    /** @type {string} */
                    datumB = b.lastprobe > 0 ? /** @type {VortragInfo} */(vortraege.get(b.lastprobe)).datum : "";
                    res = datumB.localeCompare(datumA);
                    return res == 0 ? a.id - b.id : res;
                case 4:
                    /** @type {string} */
                    datumA = a.lastvortrag > 0 ? (/** @type {VortragInfo} */(vortraege.get(a.lastvortrag)).datum) : "";
                    /** @type {string} */
                    datumB = b.lastvortrag > 0 ? /** @type {VortragInfo} */(vortraege.get(b.lastvortrag)).datum : "";
                    res = datumB.localeCompare(datumA);
                    return res == 0 ? a.id - b.id : res;
            }
        });
        let isSmall = window.screen.availWidth <= 800;
        let isXSmall = window.screen.availWidth <= 600;
        return [
            m('h2', 'Lieder'),
            m('button[type=button]#goTopBtn', { onclick: handleGoTop, title: 'Zum Anfang' }, m('i', { class: 'las la-angle-double-up' })),
            m('div#table-container', { style: 'overflow-y: scroll; height: 84vh; width:100%;' }, [
                m('table', { style: isSmall ? 'font-size:0.8em;' : 'font-size:0.95em;' }, [
                    m('thead', [
                        m('tr', [
                            m('th', { onclick: () => { sort = 0; }, }, [m('span', 'Lied-Nr.'), sort == 0 && m('i.las.la-angle-down')]),
                            m('th', { onclick: () => { sort = 1; }, }, [m('span', 'Text'), sort == 1 && m('i.las.la-angle-down')]),
                            isSmall ? null : m('th', { onclick: () => { sort = 2; }, }, [m('span', 'Status'), sort == 2 && m('i.las.la-angle-down')]),
                            isSmall ? null : m('th', { onclick: () => { sort = 3; }, }, [m('span', 'Letzte Probe'), sort == 3 && m('i.las.la-angle-down')]),
                            isSmall ? null : m('th', { onclick: () => { sort = 4; }, }, [m('span', 'Letzter Vortrag'), sort == 4 && m('i.las.la-angle-down')]),
                            m('th', m('button.button.small', { onclick: handleNewLied }, 'Neues Lied')),
                        ]),
                        m('tr', [
                            m(
                                'th.wd-6',
                                m('input[type=text]#filter_liedNr', {
                                    value: filter.liedNr,
                                    onchange: handleFilterChange,
                                })
                            ),
                            m(
                                'th.wd-24',
                                m('input[type=text]#filter_liedText', {
                                    value: filter.text,
                                    onchange: handleFilterChange,
                                })
                            ),
                            isSmall ? null : m(
                                'th.wd-6',
                                m('input[type=text]#filter_status', {
                                    value: filter.status,
                                    onchange: handleFilterChange,
                                })
                            ),
                            isSmall ? null : m(
                                'th.wd-10',
                                m('input[type=text]#filter_lastProbe', {
                                    value: filter.lastProbe,
                                    onchange: handleFilterChange,
                                })
                            ),
                            isSmall ? null :m(
                                'th.wd-10',
                                m('input[type=text]#filter_lastVortrag', {
                                    value: filter.lastVortrag,
                                    onchange: handleFilterChange,
                                })
                            ),
                            m(
                                'th',
                                m(
                                    'button.button.small',
                                    { title: 'Filter zurücksetzen', onclick: handleFilterReset },
                                    'X'
                                )
                            ),
                        ]),
                    ]),
                    m('tbody', [
                        Object.values(dispLieder).map((/** @type {LiedInfo} */lied) => {
                            const isSel = lied.id === selectedId;
                            const lastProbe = mpData.getLastProbeAttrs(lied.id);
                            const lastVortrag = mpData.getLastVortragAttrs(lied.id);
                            return m(`tr#row_${lied.id}`,
                                // @ts-ignore
                                { class: isSel ? 'selected' : null, onclick: handleRowClick },
                                [
                                    m('td', mpData.getDispLiedNr(lied.id)),
                                    m('td', lied.text),
                                    isSmall ? null : m('td', lied.status),
                                    isSmall ? null : m('td', lastProbe ? (mpData.getDispDate(lastProbe.datum) + " " + lastProbe.dirigent) : ''),
                                    isSmall ? null : m('td', lastVortrag ? (mpData.getDispDate(lastVortrag.datum) + " " + lastVortrag.dirigent) : ''),
                                    m('td', { style: 'display:flex;' }, isSel
                                        ? [
                                            m('i.las.la-edit', { onclick: handleEditLied, title: 'Bearbeiten' }),
                                            m('i.las.la-trash-alt.ml-1', { onclick: handleDeleteLied, title: 'Löschen' }),
                                        ]
                                        : ''
                                    ),
                                ]
                            );
                        }),
                    ]),
                ]),
            ]),
            showEditor && m(LiedEditor, {
                mpData,
                editLied,
                onsave: handleEditorSave,
                oncancel: handleEditorCancel,
            }),
            showConfirm && m(ConfirmDialog, {
                text: "Sind Sie sicher, dass Sie das ausgewählte Lied löschen wollen?",
                onok: handleDeleteOk,
                oncancel: handleDeleteCancel
            })
        ];
    }

    return { oninit, view };
};

const LiedEditor = () => {
    let editLied = {};
    /** 
     * @callback SaveLiedCallback 
     * @param {LiedInfo} param1
     */
    /** @type {SaveLiedCallback} */
    let onsave;
    let oncancel;

    /** @typedef {{ sammlung: string, nummer: number, suffix: string, text: string, status: string, 
     *  changes: string, dispLiedNr: string, oldIndex: number}} LiedModel */
    /** @type {LiedModel} */
    let model;

    /** @type {MappeData} */
    let mpData;
    let errors = {};

    let isNew = true;

    function oninit(vnode) {
        ({ mpData, editLied, onsave, oncancel } = vnode.attrs);
        isNew = true;
    }

     /**
     * Validates the given model and returns errors if any.
     *
     * @param {LiedModel} model - the model to be validated
     * @return {Object} errors if any, otherwise undefined
     */
    function validate(model) {
        /** @type {{ text?: string, nummer?: string, sammlung?: string, status?: string }} */
        const errors = {};
        if (!model.text) {
            errors.text = 'Text fehlt';
        }
        if (!model.nummer || model.nummer == 0) {
            errors.nummer = 'Lied-Nr. fehlt';
        }
        if (!model.sammlung) {
            errors.sammlung = 'Sammlung fehlt';
        }
        if (!model.status) {
            errors.status = 'Status fehlt';
        }
        if (Object.keys(errors).length > 0)
            return errors;

        if (model.oldIndex == 0) { // Neuer Datensatz
            const liedId = mpData.encodeLiedId({
                sammlungKuerzel: model.sammlung,
                nummer: model.nummer ?? '0',
                suffix: model.suffix,
            });
            if (mpData.lieder.has(liedId)) {
                errors.text = 'Dieses Lied existiert bereits';
                model.text = mpData.lieder.get(liedId)?.text ?? '';
                model.status = mpData.lieder.get(liedId)?.status ?? '';
            }
        }

        return errors;
    }

    function handleSave() {
        errors = validate(model);
        if (Object.keys(errors).length > 0) {
            return;
        }
        /** @type {{ id: number, text: string, status: string, changes: string }} */
        const data = {
            id: mpData.encodeLiedId({
                sammlungKuerzel: model.sammlung,
                nummer: model.nummer,
                suffix: model.suffix,
            }),
            text: model.text,
            status: model.status,
            changes: model.changes,
        };
        /** @type {LiedInfo} */
        const lied = new LiedInfo(mpData);
        lied.initialize(data);
        onsave?.(lied);
        isNew = true;
    }

    function handleCancel() {
        oncancel?.();
        isNew = true;
    }

    function view(vnode) { // LiedEditor
        ({ mpData, editLied, onsave, oncancel } = vnode.attrs);
        if (editLied && isNew) {
            const liedAttrs = mpData.decodeLiedId(editLied.id);
            model = {
                oldIndex: editLied.id,
                dispLiedNr: mpData.getDispLiedNr(editLied.id) ?? "",
                sammlung: liedAttrs.sammlungKuerzel ?? "",
                nummer: liedAttrs.nummer ?? 0,
                suffix: liedAttrs.suffix ?? "",

                text: editLied.text ?? "",
                status: editLied.status ?? "",
                changes: editLied.changes ?? "",
            };
            isNew = false;
        }

        const statusTexte = {
            U: 'Unbekannt',
            N: 'Neu',
            P: 'Proben vor Vortrag',
            R: 'Repertoire',
            S: 'Speziallied',
        };
        const statusOptions = 'UNPRS'.split('').map(c => { return { value: c, text: `${c} - ${statusTexte[c]}` } });
        const sammlungenOptions = [...mpData.sammlungen.values()].map((sammlung) => {
            return {
                value: sammlung.kuerzel2,
                text: sammlung.kuerzel2 + " - " + sammlung.text
            };
        });       
        return m('dialog#lied-editor.wd-32',
            editLied && [
                m('h2', editLied.id ? 'Lied bearbeiten' : 'Neues Lied erfassen'),
                m(InputSelect, {
                    model, field: 'sammlung', errors, classes: { lblCol: 'col-4', inputCol: 'col-8' }, label: 'Sammlung',
                    options: sammlungenOptions
                }),
                m('div.row', [
                    m('div.col-4', m('label', { for: 'liedNr' }, 'Lied-Nr.')),
                    m('div.col-4.validated-field',
                        m('input[type=number]#liedNr', {
                            value: model.nummer,
                            onchange: (/** @type {{ target: { value: string; }; }} */ e) => {
                                model.nummer =parseInt( e.target.value);
                                delete errors.nummer;
                            },
                        }),
                        m('p.text-error', errors.nummer ?? ''),
                    ),
                    m('div.col-2', m('label', { for: 'liedNr' }, 'Suffix')),
                    m('div.col-2.validated-field', m('select#suffix',
                        { value: model.suffix, onchange: (e) => (model.suffix = e.target.value) },
                        [' abcdefghijk'.split('').map((c) => m('option', { value: c }, c))]
                    )
                    ),
                ]),
                m(InputText, {
                    label: 'Text', model, field: 'text', errors,
                    classes: { lblCol: 'col-4', inputCol: 'col-8' }
                }),
                m(InputSelect, { label: 'Status', model, field: 'status', errors, classes: { lblCol: 'col-4', inputCol: 'col-8' }, options: statusOptions }),
                m(InputText, { label: 'Änderungen', model, field: 'changes', errors, classes: { lblCol: 'col-4', inputCol: 'col-8' } }),
                m(SaveCancel, { onsave: handleSave, oncancel: handleCancel })
            ] // editLied
        );
    }

    return { oninit, view };
};

export { LiederView, LiedEditor };
