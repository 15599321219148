// @ts-check
import m from 'mithril';
import { InputText, InputNumber, InputCheckbox, SaveCancel } from './components';
import MappeData from './mappe_data';
import SammlungInfo from './sammlung_info';

const SammlungenView = () => {
    /** @type {MappeData} */
    let mpData;
    
    /** @type {Map<string, SammlungInfo>} */
    let sammlungen = new Map(); 

    let selectedId = 0;
    let goToSelectedId = 0;
    let sort = 0;

    /** @type {SammlungInfo} */
    let editSammlung;
    let showEditor = false;

    const editorId = '#sammlung-editor';

    const emptyFilter = {        
        kuerzel2: '',
        text: ''            
    }
    let filter = { ...emptyFilter };

    /**
     * @param {{ attrs: { mpData: MappeData; }; }} vnode
     */
    function oninit(vnode) {        
        ({ mpData } = vnode.attrs);
        sammlungen = mpData.sammlungen;
    }

    function handleFilterChange(e) {
        const fld = e.target.id.slice(7);
        filter[fld] = (fld == "kuerzel2") ? e.target.value.toUpperCase() : e.target.value; 
        m.redraw();
    }

    function handleFilterReset() {
        filter = { ...emptyFilter };
        m.redraw();
    }

    function handleRowClick(e) {
        goToSelectedId = 0;
        selectedId = parseInt(e.currentTarget.id.slice(4));
    }

    function handleNewSammlung() {
        selectedId = 0;
        handleEditSammlung();
    }

    function handleEditSammlung() {
        editSammlung = new SammlungInfo(mpData);
        for (const [key, sammlung] of sammlungen) {
            if (sammlung.id == selectedId) {
                editSammlung = sammlung;
                break;
            }
        }

        if (editSammlung.id == 0)
            return;

        showEditor = true;
        setTimeout(() => {
            const dialog = document.querySelector(editorId);
            /** @type {HTMLDialogElement} */(dialog)?.showModal();
        }, 10);
    }

    /**
     * @param {SammlungInfo} sammlung
     */
    async function handleEditorSave(sammlung) {        
        if (!sammlung.id || sammlung.id == 0) {
            let maxId = 0;
            for (const [key, sammlung] of sammlungen) {
                maxId = sammlung.id > maxId ? sammlung.id : maxId;
            }
            sammlung.id = maxId + 1;
        }
        const dialog = document.querySelector(editorId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showEditor = false;
        await mpData.saveSammlung(sammlung, editSammlung);
        goToSelectedId = sammlung.id;
    }

    function handleEditorCancel() {
        const dialog = document.querySelector(editorId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showEditor = false;
    }    

    function getDispSammlungen() {
        /** @type {SammlungInfo[]} */

        const res = [];
        for (const [key, sammlung] of sammlungen)
        {
            if (!filter.kuerzel2 || sammlung.kuerzel2.includes(filter.kuerzel2.toUpperCase())) {
                if (!filter.text || sammlung.text.toUpperCase().includes(filter.text.toUpperCase())) {
                    res.push(sammlung);
                }
            }
        };
        return res;
    }

    function handleGoTop() {
      const divTableContainer = document.getElementById('table-container');
      if (divTableContainer) 
        divTableContainer.scrollTop = 0;      
    }

    function view(vnode) {
        ({ mpData } = vnode.attrs);
        if (!sammlungen) return null;

        const dispSammlungen = getDispSammlungen();
        if (goToSelectedId > 0 && goToSelectedId != selectedId) {
            selectedId = goToSelectedId;
            setTimeout(() => {                
                const el = document.getElementById(`row_${goToSelectedId}`);
                if (el) {
                    el.scrollIntoView();                    
                }
                goToSelectedId = 0;
            }, 100);
        }
        // @ts-ignore
        dispSammlungen.sort((a, b) => {
            switch (sort) {
                case 0:
                    return a.id - b.id;
                case 1:
                    return a.text.localeCompare(b.text);
            } 
        });
        let isSmall = window.screen.availWidth <= 800;
        return [
            m('h2', 'Sammlungen'),
            m('button[type=button]#goTopBtn', { onclick: handleGoTop, title: 'Zum Anfang' }, m('i', { class: 'las la-angle-double-up' })),
            m('div#table-container', { style: 'overflow-y: scroll; height: 84vh; width:100%;' }, [
                m('table', { style: isSmall ? 'font-size:0.8em;' : 'font-size:0.95em;' }, [
                    m('thead', [
                        m('tr', [
                            m('th', 'Id'),
                            m('th', { onclick: () => { sort = 0; m.redraw(); }, }, [m('span', 'Kürzel'), sort == 0 && m('i.las.la-angle-down')]),
                            m('th', { onclick: () => { sort = 1; m.redraw(); }, }, [m('span', 'Text'), sort == 1 && m('i.las.la-angle-down')]),
                            m('th', 'Offset'),
                            isSmall ? null : m('th', 'Aktiv'),
                            m('th', m('button.button.small', { onclick: handleNewSammlung }, 'Neue Sammlung')),
                        ]),
                        m('tr', [
                            m('th', ''),
                            m('th.wd-6',
                                m('input[type=text]#filter_kuerzel2', {
                                    value: filter.kuerzel2,
                                    onchange: handleFilterChange,
                                })
                            ),
                            m('th.wd-24',
                                m('input[type=text]#filter_text', {
                                    value: filter.text,
                                    onchange: handleFilterChange,
                                })
                            ),
                            m('th.wd-2'),
                            isSmall ? null : m('th.wd-4'),
                            m('th', 
                                m('button.button.small', { title: 'Filter zurücksetzen', onclick: handleFilterReset }, 'X')
                            ),
                        ]),
                    ]),
                    m('tbody', [
                        dispSammlungen.map((/** @type {SammlungInfo} */sammlung) => {
                            const isSel = sammlung.id === selectedId;
                            return m(
                                `tr#row_${sammlung.id}`,
                                // @ts-ignore
                                { class: isSel ? 'selected' : null, onclick: handleRowClick },
                                [
                                    m('td', sammlung.id),
                                    m('td', sammlung.kuerzel2),  
                                    m('td', sammlung.text ?? ''),
                                    m('td', sammlung.offset ?? 0),
                                    isSmall ? null : m('td', sammlung.active),
                                    m('td', { style: 'display: flex;' }, 
                                        isSel ? m('i.las.la-edit', { onclick: handleEditSammlung, title: 'Bearbeiten' }) : '')
                                ]
                            );
                        }),
                    ]),
                ]),
            ]),
            showEditor &&
                m(SammlungEditor, {
                    mpData: vnode.attrs.mpData,
                    editSammlung,
                    onsave: handleEditorSave,
                    oncancel: handleEditorCancel,
                }),
        ]; // return
    }

    return { oninit, view };
};

const SammlungEditor = () => {
    /** @type SammlungInfo */
    let editSammlung;

    /** 
     * @callback SaveSammlungCallback 
     * @param {SammlungInfo} param1
     */
    /** @type {SaveSammlungCallback} */
    let onsave;
    let oncancel;

    /** @typedef {{ id: number, kuerzel2: string, text: string, offset: number, active: boolean }} SammlungModel */
    /** @type {SammlungModel} */
    let model;

    /** @type {MappeData} */
    let mpData;

    let errors = {};

    /**
     * @param {{ attrs: { mpData: MappeData; editSammlung: SammlungInfo; onsave: any; oncancel: Function; }; }} vnode
     */
    function oninit(vnode) {
        ({ mpData, editSammlung, onsave, oncancel } = vnode.attrs);
        if (editSammlung) {
            model = {
                id: editSammlung.id,
                kuerzel2: editSammlung.kuerzel2,
                text: editSammlung.text,
                active: editSammlung.active,
                offset: editSammlung.offset
            }; 
        }
    }

    /**
     * @param {SammlungModel} model
     */
    function validate(model) {
        /** @type {{ kuerzel2?: string; text?: string }} */
        const errors = {};
        if (!model.kuerzel2) {
            errors.kuerzel2 = 'Kürzel fehlt';
        } else if (model.kuerzel2.length != 2) {
            errors.kuerzel2 = 'Genau 2 Zeichen';
        } else if (model.kuerzel2.toUpperCase() != model.kuerzel2) {
            errors.kuerzel2 = '2 Grossbuchstaben!';
        } else if (model.id == 0) { // Neuer Datensatz
            if ([...mpData.sammlungen.values()].map(sammlung => sammlung.kuerzel2).indexOf(model.kuerzel2) >= 0) {
                errors.kuerzel2 = 'Kürzel bereits vergeben';
            }
        }

        if (!model.text) {
            errors.text = 'Text fehlt';
        }
                
        return errors;
    }

    function handleSave() {
        errors = validate(model);
        if (Object.keys(errors).length > 0) {
            return;
        }        

        /** @type {SammlungInfo} */
        const sammlung = new SammlungInfo(mpData);
        sammlung.initialize(model);

        onsave?.(sammlung);
    }

    function handleCancel() {
        oncancel?.();
    } 

    function view(vnode) {
        ({ mpData } = vnode.attrs);
        
        return m('dialog#sammlung-editor.wd-32',
            editSammlung && [
                m('h2', editSammlung.id ? 'Sammlung bearbeiten' : 'Neue Sammlung erfassen'),

                m(InputText, { model, field: 'kuerzel2', label: 'Kürzel', classes: { inputCol: 'col-2' }, errors: errors }),                
                m(InputText, { model, field: 'text', label: 'Text', errors: errors }),
                m(InputNumber, { model, field: 'offset', label: 'Offset', classes: { lblCol: 'col-2', inputCol: 'col-4' },  errors: errors }),
                m(InputCheckbox, { model, field: 'active', label: 'Aktiv', classes: { lblCol: 'col-2' }, errors: errors }),

                m(SaveCancel, { onsave: handleSave, oncancel: handleCancel }),
            ] // editSammlung            
        );
    }

    return { oninit, view };
}

export { SammlungenView, SammlungEditor };
