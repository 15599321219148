// @ts-check
import m from 'mithril';
import dayjs from 'dayjs';

import MappeData from './mappe_data';

import { MainMenu } from './mainmenu';
import { ListenView } from './listen';
import { LiederView } from './lieder';
import { ProbenView } from './proben';
import { VortraegeView } from './vortraege';
import { SammlungenView } from './sammlungen';
import { DirigentenView } from './dirigenten';
import { GemeindeView } from './gemeinde';
import { LoginView } from './login';

const Main = () => {
    let viewIndex = 0;
    /** @type {MappeData | {}} */
    let mpData = {};
    let loading = false;
    let token = '';

    let msgText = '';
    /** @type {string} */
    let msgType = 'error';

    let showBars = window.innerWidth < 900;

    const progVersion = '0.6.1';

    // const baseurl = 'http://localhost/datastore/';
    const baseurl = 'https://wibe4153.ch/datastore/';

    const oninit = () => {
        token = sessionStorage.mappe2024_token ?? '';
        viewIndex = parseInt(sessionStorage.mappe2024_lastViewIndex) || 0;
        const lastLogin = sessionStorage.mappe2024_lastLogin;
        if (lastLogin) {
            const maxLoginTime = dayjs(lastLogin).add(23, 'hour');
            if (maxLoginTime.isBefore(dayjs())) {
                sessionStorage.removeItem('mappe2024_token');
                sessionStorage.removeItem('mappe2024_lastLogin');                
                token = '';
            }
        }
        if (token) {
            loadData();
        }
    };

    /**
     * @param {string} response
     */
    async function handleLoginSuccess(response) {
        token = response ?? '';
        sessionStorage.setItem('mappe2024_token', token);
        sessionStorage.setItem('mappe2024_lastLogin', dayjs().format('YYYY-MM-DDTHH:mm:ss'));
        await loadData();
    }

    async function loadData() {
        if (!token) return;        
        loading = true;
        m.redraw();
        try {
            mpData = await MappeData.getInstance().loadData(baseurl, token, setMsg);        
        } catch (error) {
            setMsg(`Fehler beim Laden der Daten - ${error?.message}`, 'error', 0);
        }        
        loading = false;
        m.redraw();
    }    

    /**
     * @param {{ target: { id: string; }; preventDefault: () => void; }} e
     */
    function handleMenuClick(e) {
        if (e.target.id == 'bars') {
            showBars = !showBars;            
            return;
        } else if (e.target.id == 'restart') {
            sessionStorage.removeItem('mappe2024_token');
            sessionStorage.removeItem('mappe2024_lastLogin');
            token = '';
            window.location.reload();
            e.preventDefault();
            return;
        }
        if (!e.target.id || e.target.id == 'myTopnav') return;        
        viewIndex = parseInt(e.target.id.slice(3));
        if (Number.isNaN(viewIndex) || viewIndex < 0 || viewIndex >= 7 ) {
            alert("Ungültiger viewIndex");
            viewIndex = 0;
        }
        sessionStorage.setItem('mappe2024_lastViewIndex', `${viewIndex}`);
        showBars = true;
    }

    /**
     * @param {string} msg
     * @param {string} [type=""]
     * @param {number} [seconds=0]
     */
    function setMsg(msg, type="", seconds = 0) {
        msgText = msg;        
        msgType = type; // 'success', 'warning' oder 'error'
        if (seconds > 0) {
            setTimeout(() => {
                msgText = '';
                msgType = '';
                m.redraw();
            }, seconds * 1000);
        }
    }

    const view = () => {        
        const views = [ListenView, LiederView, ProbenView, VortraegeView, SammlungenView, DirigentenView, GemeindeView];
        /** @type { any} */
        const View = views[viewIndex];
        const isSmall = window.screen.availWidth <= 800;
        return [           
            msgText && m(`p.message.bg-${msgType}`, { onclick: () => (msgText = '') }, msgText),
            token && m('aside', {}, [
                // @ts-ignore
                m(MainMenu, { viewIndex, showBars, onclick: handleMenuClick })
            ]),
            m('div' + `#view${viewIndex}`, { class: 'row', style: 'height: 94em; padding: 1em;' + (isSmall ? 'overflow-y: scroll;' : '') }, [                
                m('div.col-12', [ 
                    loading
                    ? m(`p.message.bg-warning`, 'Bitte warten, lade Daten...')
                    : token
                        ? m(View, { mpData, setMsg })
                        : m(LoginView, { baseurl, onsuccess: handleLoginSuccess, setMsg }),                
                ])
            ])
        ];
    };

    return { oninit, view };
};

const root = document.querySelector('#app');

m.mount(/** @type {Element} */(root), Main);
