// @ts-check
class PdfGenReport
{
    /** 
     * @typedef {Object<string, number | string>} PdfCmd
     */
    /** @type {PdfCmd[]} */
    PdfDocument = [];

    /** @type {PdfCmd[]} */
    Body = [];

    /** @type {PdfCmd[]} */
    FirstHeader = [];

    /** @type {PdfCmd[]} */
    Header = [];

    /** @type {PdfCmd[]} */
    Footer = [];

    /** @type {PdfCmd[]} */
    LastFooter = [];

    currentSection = this.PdfDocument;

    Sections = {
        Document: 1,
        Body: 2,
        FirstHeader: 3,
        Header: 4,
        Footer: 5,
        LastFooter: 6
    }

    /**
     * @param {number} section
     */
    selectSection(section)
    {
        switch (section)
        {
            case this.Sections.Document:
                this.currentSection = this.PdfDocument;
                break;
            case this.Sections.Body:
                this.currentSection = this.Body;
                break;
            case this.Sections.FirstHeader:
                this.currentSection = this.FirstHeader;
                break;
            case this.Sections.Header:
                this.currentSection = this.Header;
                break;
            case this.Sections.Footer:
                this.currentSection = this.Footer;
                break;
            case this.Sections.LastFooter:
                this.currentSection = this.LastFooter;
                break;
            default:
                break;
        }
    }


    /**
     * @param {PdfCmd[]} elements
     */
    addElements(elements)
    {
        this.currentSection.push(...elements);
    }

    /**
     * Initialize the pdfgen-Engine
     * @param {string} orient
     * @param {string} unit
     * @param {string} format
     * @param {string} [docname="pdfgen_report"]
     */
    init(orient, unit, format, docname = "pdfgen_report.pdf")
    {
        this.currentSection.push({ TYPE: "INIT", orient, unit, format, docname });
    }

    /**
     * @param {string} title
     * @param {string} author
     * @param {string} [creator="PdfGen"]
     * @param {string} [subject]
     * @param {string} [keywords]
     */
    setData(title, author, creator = "PdfGen", subject = "", keywords = "")
    {
        this.currentSection.push({ TYPE: "SETDATA", title, author, creator, subject, keywords });
    }

    /**
     * 
     * @param {string} [orient="P"] 
     */
    addPage(orient = "P")
    {
        this.currentSection.push({ TYPE: "ADDPAGE", orient });
    }

    /**
     * @param {number} width
     * @param {number} height
     * @param {string} text
     * @param {string} [align="L"]
     * @param {number} [lf=0]
     * @param {string} [border]
     * @param {string} [fill]
     */
    cell(width, height, text, align = "L", lf = 0, border = "", fill = "")
    {
        this.currentSection.push({ TYPE: "CELL", w: `${width}`, h: `${height}`, text, align, lf: `${lf}`, border, fill });
    }
    /**
     * 
     * @param {boolean} [block=false]
     */

    blockPageBreak(block = false) {
        this.currentSection.push({ TYPE: "BLOCKPAGEBREAK", block: `${block ? 1 : 0}` });
    }

    /**
     * @param {number} width
     * @param {number} height
     * @param {string} [text]
     * @param {string} [align="C"]
     * @param {string} [border="LTRB"]
     * @param {string} [fill]
     */
    borderedCell(width, height, text = "", align = "C", border = "LTRB", fill = "") {
        this.cell(width, height, text, align, 0, border, fill);
    }

    /**
     * @param {number} x1
     * @param {number} y1
     * @param {number} x2
     * @param {number} y2
     * @param {number} [width=0.2]
     */
    line(x1, y1, x2, y2 = 0, width = 0.2)
    {
        this.currentSection.push({ TYPE: "LINE", x1: `${x1}`, y1: `${y1}`, x2: `${x2}`, y2: `${y2}`, wd: `${width}` });
    }

    /**
     * @param {string} file
     * @param {number} x
     * @param {number} y
     * @param {number} w
     * @param {number} h
     * @param {string} [type="png"]
     */
    image(file, x, y, w, h, type="png")
    {
        this.currentSection.push({ TYPE: "IMAGE", file, x: `${x}`, y: `${y}`, w: `${w}`, h: `${h}`, type });
    }

    ln(height = 0, save = "")
    {
        if (save && save[0] != "#") {
            save = "#" + save;
        }
        this.currentSection.push({ TYPE: "LN", h: `${height}`, save });
    }

    /**
     * @param {number} width
     * @param {number} height
     * @param {string} text
     * @param {string} [align="L"]
     * @param {string} [border]
     * @param {string} [fill]
     */
    multiCell(width, height, text, align = "L", border = "", fill = "")
    {
        this.currentSection.push({ TYPE: "MULTICELL", w: `${width}`, h: `${height}`, text, align, border, fill });
    }

    /**
     * 
     * @param {string} [dest="I"] 
     * @param {string} [name] 
     */
    output(dest = "I", name = "")
    {
        this.currentSection.push({ TYPE: "OUTPUT", dest, name });
    }

    resetFont()
    {
        this.currentSection.push({ TYPE: "RESETFONT" });
    }

    /**
     * 
     * @param {number} angle 
     */
    rotate(angle)
    {
        this.currentSection.push({ TYPE: "ROTATE1", angle: `${angle}` });
    }

    /**
     * @param {number} angle
     * @param {number} [x=-1]
     * @param {number} [y=-1]
     */
    rotate3(angle, x = -1, y = -1)
    {
        this.currentSection.push({ TYPE: "ROTATE3", angle: `${angle}`, x: `${x}`, y: `${y}` });
    }

    /**
     * @param {string} save
     */
    saveX(save) {
        if (save && !save.startsWith("#"))
        {
            save = "#" + save;
        }
        this.currentSection.push({ TYPE: "SAVEX", "SAVE": save });
    }

    /**
     * @param {string} save
     */
    saveY(save)
    {
        if (save && !save.startsWith("#"))
        {
            save = "#" + save;
        }
        this.currentSection.push({ TYPE: "SAVEY", "SAVE": save });
    }

    /**
     * 
     * @param {string} [family="Arial"] 
     * @param {number} [size=10] 
     */
    setDefaultFont(family = "Arial", size = 10)
    {
        this.currentSection.push({ TYPE: "SETDEFAULTFONT", family, size: `${size}` });
    }

    /**
     * @param {number} r
     */
    setDrawColor(r)
    {
        this.currentSection.push({ TYPE: "SETDRAWCOLOR1", r: `${r}` });
    }

    /**
     * @param {number} r
     * @param {number} g
     * @param {number} b
     */
    setDrawColorRGB(r, g, b)
    {
        this.currentSection.push({ TYPE: "SETDRAWCOLOR3", r: `${r}`, g: `${g}`, b: `${b}` });
    }

    /**
     * 
     * @param {string} [family="Arial"]
     * @param {number} [size=10]
     * @param {string} [style] 
     */
    setFont(family = "Arial", size = 10, style = "")
    {
        this.currentSection.push({ TYPE: "SETFONT", family, size: `${size}`, style });
    }

    /**
     * 
     * @param {number} [w=2.0] 
     */
    spacing(w = 2.0)
    {
        this.cell(w, 1, "");
    }

    /**
     * 
     * @param {number} [width=0.1]
     */
    setLineWidth(width = 0.1) 
    {
        this.currentSection.push({ TYPE: "SETLINEWIDTH", width: `${width}` });
    }

    /**
     * 
     * @param {number} left 
     * @param {number} top 
     * @param {number} right 
     * @param {boolean} keep 
     */
    setMargins(left = 10, top = 10, right = 0, keep = false)
    {
        this.currentSection.push({ TYPE: "SETMARGINS", left: `${left}`, top: `${top}`, right: `${right}`, keep: `${keep ? 1 : 0}` });
    }

    /**
     * 
     * @param {number} x 
     */
    setX(x = 10)
    {
        this.currentSection.push({ TYPE: "SETX", x: `${x}` });
    }

    setY(y = 10)
    {
        this.currentSection.push({ TYPE: "SETY", y: `${y}` });
    }

    /**
     * 
     * @param {number} x 
     * @param {number} y 
     */
    setXY(x = 10, y = 10)
    {
        this.currentSection.push({ TYPE: "SETXY", x: `${x}`, y: `${y}` });
    }

    /**
     * @param {number} x
     * @param {number} y
     * @param {string} text
     * @param {string} [style]
     */
    text(x, y, text, style = "")
    {
        this.currentSection.push({ TYPE: "SETX", x: `${x}`, y: `${y}`, text, style });
    }

    /**
     * @param {number} h
     * @param {string} text
     * @param {string} [style]
     */
    write(h, text, style = "")
    {
        this.currentSection.push({ TYPE: "WRITE", h: `${h}`, text, style });
    }

    getReport() {
        return { 
            Document: this.PdfDocument, 
            Body: this.Body, 
            FirstHeader: this.FirstHeader, 
            Header: this.Header,
            Footer: this.Footer, 
            LastFooter: this.LastFooter
        };
    }   
}

export default PdfGenReport;