// @ts-check
import m from 'mithril';
import dayjs from 'dayjs';

import { InputText, InputSelect, SaveCancel, ConfirmDialog } from './components';

import MappeData from './mappe_data';
import DirigentInfo from './dirigent_info';
import LiedInfo from './lied_info';
import ProbeInfo from './probe_info';

const ProbenView = () => {
    /** @type {MappeData} */
    let mpData;

    /** @type {Map.<number, ProbeInfo>} */
    let proben = new Map();

    /** @type {ProbeInfo} */
    let editProbe;

    let selectedId = 0;
    let goToSelectedId = 0;
    let sort = 0;
    
    let showEditor = false;
    let showConfirm = false;

    const editorId = '#probe-editor';
    const confirmId = '#confirm-dialog';    

    const emptyFilter = {
        datum: '',
        dirigent: '',
        result: '',
        liedid: ''
    }
    let filter = { ...emptyFilter };

    function oninit(vnode) {
        ({ mpData } = vnode.attrs);
        proben = mpData.proben;
    }

    function handleFilterChange(e) {
        const fld = e.target.id.slice(7);
        filter[fld] = (fld == "dirigent" || fld == "result") ? e.target.value.toUpperCase() : e.target.value;        
        m.redraw();
    }

    function handleFilterReset() {
        filter = { ...emptyFilter };
        m.redraw();
    }

    /**
     * @param {{ currentTarget: { id: string; }; }} e
     */
    function handleRowClick(e) {
        goToSelectedId = 0;
        selectedId = parseInt(e.currentTarget.id.slice(4));
    }

    function handleNewProbe() {
        selectedId = 0;
        handleEditProbe();
    }

    
    function handleEditProbe() {
        editProbe = selectedId > 0 && proben.has(selectedId) ? /** @type {ProbeInfo} */(proben.get(selectedId)) : new ProbeInfo(mpData);
        showEditor = true;
        setTimeout(() => {            
            const dialog = document.querySelector(editorId);
            /** @type {HTMLDialogElement} */(dialog)?.showModal();
        }, 10);
    }

    async function handleDeleteProbe() {
        if (selectedId <= 0)
            return;
        showConfirm = true;
        setTimeout(() => {
            const dialog = document.querySelector(confirmId);
            /** @type {HTMLDialogElement} */(dialog)?.showModal();
        }, 10)    
    }

    async function handleDeleteOk() {
        /** @type {ProbeInfo} */
        const probe = /** @type {ProbeInfo} */(proben.get(selectedId));
        const dialog = document.querySelector(confirmId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showConfirm = false;
        await mpData.deleteProbe(probe);
    }

    async function handleDeleteCancel() {
        const dialog = document.querySelector(confirmId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showConfirm = false;
    }

    /** 
     * @param {ProbeInfo} probe 
     * @returns {Promise<void>}
     * @async
     */
    async function handleEditorSave(probe) {
        if (!probe.id || probe.id == 0) {
            const arr = [ ...mpData.proben.values() ];
            const maxId = Math.max(...arr.map(obj => obj.id));
            probe.id = maxId + 1;
        }
        const dialog = document.querySelector(editorId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showEditor = false;
        await mpData.saveProbe(probe, editProbe);
        goToSelectedId = probe.id;
    }

    function handleEditorCancel() {
        const dialog = document.querySelector(editorId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showEditor = false;
    }

    /**
     * Normalize the displayed lied number.
     *
     * @param {string} dispLiedNr - The displayed lied number to be normalized.
     * @return {string} - The normalized lied number.
     */
    function normalizeDispLiedNr(dispLiedNr) {
        if (!dispLiedNr) return "";
        if (!dispLiedNr.match(/[A-Za-z]{2}[ ]?[0-9]{1,3}[a-i]*/g)) return "";
        return dispLiedNr.replace(/\s/g, '').toUpperCase();
    }

    function getDispProben() {
        const normalizedFilterLiedNr = normalizeDispLiedNr(filter.liedid);
        return [ ...proben.values() ].filter((probe) => {
            if (normalizedFilterLiedNr) {
                const dispLiedNr = mpData.getDispLiedNr(probe.liedid);
                if (!normalizeDispLiedNr(dispLiedNr).includes(normalizedFilterLiedNr)) {
                    return false;
                }
            } else if (filter.liedid) {
                if (!probe.getLied().text.includes(filter.liedid)) {
                    return false;
                }
            }
            if (filter.dirigent && !probe.dirigent.includes(filter.dirigent.toUpperCase())) {
                return false;
            }
            if (filter.result && !filter.result.toUpperCase().includes(probe.result)) {
                return false;
            }
            if (filter.datum) {
                const datum = mpData.getDispDate(probe.datum);
                if (!datum.includes(filter.datum)) {
                    return false;
                }
            }
            return true;
        });
    }

    function handleGoTop() {
        const divTableContainer = document.getElementById('table-container');
        if (divTableContainer)
            divTableContainer.scrollTop = 0;
    }

    function view(vnode) {
        ({ proben } = vnode.attrs.mpData);
        if (!proben) return null;
        const dispProben = getDispProben();
        if (goToSelectedId > 0 && goToSelectedId != selectedId) {
            selectedId = goToSelectedId;
            setTimeout(() => {
                const el = document.getElementById(`row_${goToSelectedId}`);
                if (el) {
                    el.scrollIntoView();
                }
                goToSelectedId = 0;
            }, 100);
        }
        dispProben.sort((a, b) => {
            let res;
            switch (sort) {
                case 0:
                    res = b.datum.localeCompare(a.datum);
                    if (res == 0)
                        res = a.dirigent.localeCompare(b.dirigent);
                    if (res == 0)
                        res == a.liedid - b.liedid;
                    return res;
                case 1:
                    res = a.dirigent.localeCompare(b.dirigent);
                    if (res == 0)
                        res = b.datum.localeCompare(a.datum);
                    if (res == 0)
                        res == a.liedid - b.liedid;
                    return res;
                case 2:
                    return a.liedid - b.liedid;
                case 3:
                    res = a.result.localeCompare(b.result);
                    if (res == 0)
                        res = b.datum.localeCompare(a.datum);
                    if (res == 0)
                        res == a.liedid - b.liedid;
                    return res;
                default:
                    return 1;
            }
        });
        const isSmall = window.screen.availWidth <= 800;
        return [
            m('h2', 'Proben'),
            m('button[type=button]#goTopBtn', { onclick: handleGoTop, title: 'Zum Anfang' }, m('i', { class: 'las la-angle-double-up' })),
            m('div#table-container', { style: 'overflow-y: scroll; height: 84vh; width:100%;' }, [
                m('table', { style: isSmall ? 'font-size:0.8em;' : 'font-size:0.95em;' }, [
                    m('thead', [
                        m('tr', [
                            m('th', { onclick: () => { sort = 0; }, }, [m('span', 'Datum'), sort == 0 && m('i.las.la-angle-down')]),
                            isSmall ? null : m('th', { onclick: () => { sort = 1; }, }, [m('span', 'Dirigent'), sort == 1 && m('i.las.la-angle-down')]),
                            m('th', { onclick: () => { sort = 2; }, }, [m('span', 'Lied'), sort == 2 && m('i.las.la-angle-down')]),
                            isSmall ? null : m('th', { onclick: () => { sort = 3; }, }, [m('span', 'Ergebnis'), sort == 3 && m('i.las.la-angle-down')]),
                            m('th', m('button.button.small', { onclick: handleNewProbe }, 'Neue Probe')),
                        ]),
                        m('tr', [
                            m(
                                'th.wd-6',
                                m('input[type=text]#filter_datum', {
                                    value: filter.datum,
                                    onchange: handleFilterChange,
                                })
                            ),
                            isSmall ? null : m(
                                'th.wd-6',
                                m('input[type=text]#filter_dirigent', {
                                    value: filter.dirigent,
                                    onchange: handleFilterChange,
                                })
                            ),
                            m(
                                'th.wd-24',
                                m('input[type=text]#filter_liedid', {
                                    value: filter.liedid,
                                    onchange: handleFilterChange,
                                })
                            ),
                            isSmall ? null : m(
                                'th.wd-10',
                                m('input[type=text]#filter_result', {
                                    value: filter.result,
                                    onchange: handleFilterChange,
                                })
                            ),
                            m(
                                'th',
                                m(
                                    'button.button.small',
                                    { title: 'Filter zurücksetzen', onclick: handleFilterReset },
                                    'X'
                                )
                            ),
                        ]),
                    ]),
                    m('tbody', [
                        Object.values(dispProben).map((/** @type {ProbeInfo} */probe) => {
                            const isSel = (probe.id === selectedId);
                            /** @type {LiedInfo} */
                            const lied = probe.getLied();
                            return m(
                                `tr#row_${probe.id}`,
                                // @ts-ignore
                                { class: isSel ? 'selected' : null, onclick: handleRowClick },
                                [
                                    m('td', mpData.getDispDate(probe.datum)),
                                    isSmall ? null : m('td', probe.dirigent),
                                    m('td.limit-text', lied.getDispLiedNr() + " - " + lied.text),
                                    isSmall ? null : m('td', probe.result),
                                    m('td', { style: 'display: flex;' }, isSel
                                        ? [
                                            m('i.las.la-edit', { onclick: handleEditProbe, title: 'Bearbeiten' }),
                                            m('i.las.la-trash-alt.ml-1', { onclick: handleDeleteProbe, title: 'Löschen' })
                                        ]
                                        : ''
                                    ),
                                ]
                            );
                        }),
                    ]),
                ]),
            ]),
            showEditor && m(ProbeEditor, {
                mpData: vnode.attrs.mpData,
                editProbe,
                onsave: handleEditorSave,
                oncancel: handleEditorCancel,
            }),
            showConfirm && m(ConfirmDialog, {
                text: "Sind Sie sicher, dass Sie die ausgewählte Probe löschen wollen?",
                onok: handleDeleteOk,
                oncancel: handleDeleteCancel
            })
        ]; // return
    }

    return { oninit, view };
};

const ProbeEditor = () => {
    /** @type {ProbeInfo} */
    let editProbe;

    /** 
     * @callback SaveProbeCallback 
     * @param {ProbeInfo} param1
     */
    /** @type {SaveProbeCallback} */
    let onsave;
    let oncancel;

    /** @typedef {{ id: number, liedid: number, sammlung: string, nummer: number, suffix: string, datum: string,
     * result: string, dirigent: string, dispText: string, dispDatum: string, dispLiedNr: string, }} ProbeModel */
    /** @type ProbeModel */
    let model;

    /** @type {MappeData} */
    let mpData;

    let errors = {};

    /**
     * @param {{ attrs: { mpData: MappeData; editProbe: ProbeInfo; onsave: (param1: ProbeInfo) => any; oncancel: any; }; }} vnode
     */
    function oninit(vnode) {
        ({ mpData, editProbe, onsave, oncancel } = vnode.attrs);
        if (editProbe) {
            const liedAttrs = mpData.decodeLiedId(editProbe.liedid);
            const isNewProbe = (editProbe.id == 0);
            const lied = (editProbe.liedid > 0 && mpData.lieder.has(editProbe.liedid)) ? /** @type {LiedInfo} */(mpData.lieder.get(editProbe.liedid)) : new LiedInfo(mpData);             
            const lastProbeDatum = sessionStorage.mappe2024_lastProbeDatum ?? dayjs().format('YYYY-MM-DD');
            const datum = isNewProbe ? lastProbeDatum : editProbe.datum;
            const lastProbeDirigent = sessionStorage.mappe2024_lastProbeDirigent ?? '';
            model = {
                id: editProbe.id,
                liedid: editProbe.liedid,                
                dirigent: isNewProbe ? lastProbeDirigent : editProbe.dirigent,
                result: editProbe.result,
                datum,

                dispDatum: mpData.getDispDate(datum),
                dispLiedNr: lied.getDispLiedNr(),
                dispText: lied.text,
                sammlung: liedAttrs.sammlungKuerzel,
                nummer: liedAttrs.nummer,
                suffix: liedAttrs.suffix,
            };
        }
    }

    /**
     * @param {ProbeModel} model
     */
    function validate(model) {
        /** @type {{ dispDatum?: string, dispLiedNr?: string, sammlung? : string, result?: string, dirigent?: string }} */
        const errors = {};
        if (!model.dispDatum) {
            errors.dispDatum = 'Datum fehlt';
        }

        if (!model.dispLiedNr || !model.dispText) {
            errors.dispLiedNr = 'Lied-Nr. fehlt oder ungültig';
        }

        if (!model.sammlung) {
            errors.sammlung = 'Sammlung fehlt';
        }

        if (!model.result) {
            errors.result = 'Ergebnis fehlt';
        }

        if (!model.dirigent) {
            errors.dirigent = 'Dirigent fehlt';
        }
        return errors;
    }

    function handleSave() {
        errors = validate(model);
        if (Object.keys(errors).length > 0) {
            return;
        }
        /** @type {{ id: number, liedid: number, dirigent: string, result: string, datum: string }} */
        const data = {
            id: model.id,
            liedid: mpData.encodeLiedId({
                sammlungKuerzel: model.sammlung,
                nummer: model.nummer,
                suffix: model.suffix,
            }),
            dirigent: model.dirigent,
            result: model.result,
            datum: mpData.getSqlDate(model.dispDatum)
        };
        if (model.id == 0) {
            sessionStorage.setItem('mappe2024_lastProbeDatum', data.datum);
            sessionStorage.setItem('mappe2024_lastProbeDirigent', data.dirigent);    
        }
        /** @type {ProbeInfo} */
        const probe = new ProbeInfo(mpData);
        probe.initialize(data);
        onsave?.(probe);
    }

    function handleCancel() {
        oncancel?.();
    }

    /**
     * @param {{ target: { value: string; }; }} e
     */
    function handleDispLiedNrChange(e) {
        model.dispLiedNr = e.target.value.trim();
        delete errors.dispLiedNr;

        const regex = /^(?<sammlung>[A-Za-z]{2})\s*(?<nummer>[0-9]{1,3}){1}(?<suffix>[a-z]*)/g;
        const match = regex.exec(model.dispLiedNr ?? '');
        if (match && match.groups) {
            model.sammlung = match.groups.sammlung.toUpperCase();
            model.nummer = parseInt(match.groups.nummer);
            model.suffix = match.groups.suffix.toLowerCase();
        } else {
            errors.dispLiedNr = 'Ungültige Lied-Nr.';
            return;
        }
        model.liedid = mpData.encodeLiedId({
            sammlungKuerzel: model.sammlung,
            nummer: model.nummer,
            suffix: model.suffix
        });
        model.dispText = "";
        model.dispLiedNr = "";
        if (mpData.lieder.has(model.liedid)) {
            model.dispLiedNr = mpData.getDispLiedNr(model.liedid);
            const lied = /** @type {LiedInfo} */(mpData.lieder.get(model.liedid));
            model.dispText = lied.text;    
        }
    }

    /**
     * @param {{ attrs: { mpData: MappeData; editProbe: ProbeInfo; onsave: any; oncancel: Function; }; }} vnode
     */
    function view(vnode) {        
        const resultOptions = [
            { value: 'V', text: 'V - Vortragsreif' },
            { value: 'W', text: 'W - Nochmals proben' }
        ];
        const dirigentOptions = [...mpData.dirigenten.keys()].map((key) => {
            /** @type {DirigentInfo} */
            const dirigent = /** @type {DirigentInfo} */(mpData.dirigenten.get(key));
            return { value: dirigent.kuerzel, text: `${dirigent.kuerzel} - ${dirigent.text}` };
        });
        return m('dialog#probe-editor.wd-32',
            editProbe && [
                m('h2', editProbe.id ? 'Probe bearbeiten' : 'Neue Probe erfassen'),

                m(InputText, { label: 'Datum', model, field: 'dispDatum', classes: { lblCol: 'col-4', inputCol: 'col-4' }, errors }),
                m(InputSelect, { label: 'Dirigent', model, field: 'dirigent', classes: { lblCol: 'col-4', inputCol: 'col-8' }, errors, options: dirigentOptions }),
                m(InputText, {
                    label: 'Lied-Nr.', model, field: 'dispLiedNr', classes: { lblCol: 'col-4', inputCol: 'col-8' }, placeholder: 'CB 123a',
                    errors, onchange: handleDispLiedNrChange
                }),
                m('div.row', [
                    m('div.col-4', m('label', { for: 'dispText' }, 'Text')),
                    m('div.col-8', model.dispText)
                ]),
                m(InputSelect, { label: 'Ergebnis', model, field: 'result', classes: { lblCol: 'col-4', inputCol: 'col-8' }, errors, options: resultOptions }),

                m(SaveCancel, { onsave: handleSave, oncancel: handleCancel })
            ] // editProbe            
        );
    }

    return { oninit, view };
}

export { ProbenView, ProbeEditor };
