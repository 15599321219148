// @ts-check

import MappeData from "./mappe_data";
import LiedInfo from './lied_info';

/** @class */
class VortragInfo {
    /** @type {number} */
    #id = 0;

    /** @type {string} */
    #datum = "";

    #liedid = 0;

    #dirigent = "";

    /** @type {MappeData} */
    #mpData;

    constructor(/** @type {MappeData} */mpData) {
        this.#mpData = mpData;
    }

    initialize(/** @type {Object.<string, any>} */ jsonData) {
        this.#id = jsonData['id'] ?? 0;
        this.#datum = jsonData['datum'] ?? "";
        this.#liedid = jsonData['liedid'] ?? 0;
        this.#dirigent = jsonData['dirigent'] ?? "";
    }

    /**
     * @returns Object.<string, any>
     */
    getJsonData() {
        return {
            id: this.#id,
            datum: this.#datum,
            liedid: this.#liedid,
            dirigent: this.#dirigent,
        };
    }

    get id() { return this.#id; }
    set id(val) { this.#id = val;}

    get datum() { return this.#datum; }
    set datum(val) { this.#datum = val; }
    
    get liedid() { return this.#liedid; }
    set liedid(val) { this.#liedid = val;}

    get dirigent() { return this.#dirigent; }
    set dirigent(val) { this.#dirigent = val; }

    /**
     * @returns {string}
     */
    getDispDatum() {
        if (!this.#datum) 
            return "";
        return this.#mpData.getDispDate(this.#datum);
    }

    /**
     * @param {string} dispDatum
     */
    setDispDatum(dispDatum) {
        if (!dispDatum) {
            this.#datum = "";
        }
        this.#datum = this.#mpData.getSqlDate(dispDatum);
    }

    /**
     * @returns LiedInfo
     */
    getLied() {
        if (!this.#liedid || !this.#mpData.lieder.has(this.#liedid)) {
            return new LiedInfo(this.#mpData);
        }
        return /** @type {LiedInfo} */(this.#mpData.lieder.get(this.#liedid));
    }
}

export default VortragInfo;