// @ts-check

import MappeData from "./mappe_data";

/** @class */
class GemeindeInfo {
    /** @type {string} */
    #name = "";

    /** @type {number} */
    #sperrfrist = 90;      // Vortragssperre für alle Lieder (Tage nach letztem Vortrag)

    // @type {number} */
    #pseitprobe = 30;      // Vortragsbereitschaft für P-Lieder (Tage nach Probe)

    // @type {number} */
    #rprobeintervall = 180; // Probeintervall für R-Lieder (Tage seit letzter Probe)
    
    // @type {number} */
    #wtprobe = 2;            // Wochentag der Chorprobe (0 = Sonntag)

    // @type {number} */
    #wtgd = 3;               // Wochentag des Wochen-GD (0 = Sonntag)
    
    // @type {number} */
    #sshow = true;           // S-Lieder in Tabellen und Listen anzeigen
    
    // @type {string} */
    #timestamp = "";

    // @type {string} */
    #version = "0.00";

    // @type {string} */
    #dirigenten = "";  // obsolet!


    /** @type {MappeData} */
    #mpData;

    constructor(/** @type {MappeData} */mpData) {
        this.#mpData = mpData;
    }

    initialize(/** @type {Object.<string, any>} */ jsonData) {
        this.#name = jsonData['name'] ?? "";
        this.#sperrfrist = jsonData['sperrfrist'] ?? 90;
        this.#pseitprobe = jsonData['pseitprobe'] ?? 30;
        this.#rprobeintervall = jsonData['rprobeintervall'] ?? 180;
        this.#wtprobe = jsonData['wtprobe'] ?? 2;
        this.#wtgd = jsonData['wtgd'] ?? 2;
        this.#sshow = jsonData['sshow'] ?? true;
        this.#timestamp = jsonData['timestamp'] ?? '';
        this.#version = jsonData['version'] ?? '';
        this.#dirigenten = jsonData['dirigenten'] ?? '';
    }

    /**
     * @returns Object.<string, any>
     */
    getJsonData() {
        return {
            name: this.#name,
            sperrfrist: this.#sperrfrist,
            pseitprobe: this.#pseitprobe,
            rprobeintervall: this.#rprobeintervall,
            wtprobe: this.#wtprobe,
            wtgd: this.#wtgd,
            sshow: this.#sshow,
            timestamp: this.#timestamp,
            version: this.#version,
            dirigenten: this.#dirigenten ?? ""
        };
    }

    get name() { return this.#name; }
    set name(val) { this.#name = val; }

    get sperrfrist() { return this.#sperrfrist; }
    set sperrfrist(val) { this.#sperrfrist = val; }

    get pseitprobe() { return this.#pseitprobe; }
    set pseitprobe(val) { this.#pseitprobe = val; }

    get rprobeintervall() { return this.#rprobeintervall; }
    set rprobeintervall(val) { this.#rprobeintervall = val; }

    get wtprobe() { return this.#wtprobe; }
    set wtprobe(val) { this.#wtprobe = val; }

    get wtgd() { return this.#wtgd; }
    set wtgd(val) { this.#wtgd = val; }

    get sshow() { return this.#sshow; }
    set sshow(val) { this.#sshow = val; }

    get timestamp() { return this.#timestamp; }
    set timestamp(val) { this.#timestamp = val; }

    get version() { return this.#version; }
    set version(val) { this.#version = val; }

    get dirigenten() { return this.#dirigenten; }
    set dirigenten(val) { this.#dirigenten = val; }
}

export default GemeindeInfo;