// @ts-check
import m from 'mithril';
import dayjs from 'dayjs';
import { InputText, InputSelect, SaveCancel, ConfirmDialog } from './components';

import MappeData from './mappe_data';
import LiedInfo from './lied_info';
import VortragInfo from './vortrag_info';
import DirigentInfo from './dirigent_info';


const VortraegeView = () => {
    /** @type {MappeData} */
    let mpData;

    /** @type {Map<number, VortragInfo>} */
    let vortraege;

    /** @type {VortragInfo} */
    let editVortrag;

    let selectedId = 0;
    let goToSelectedId = 0;
    let sort = 0;    
    let showEditor = false;
    let showConfirm = false;
    
    const editorId = '#vortrag-editor';
    const confirmId = '#confirm-dialog';

    /** @type {VortragInfo} */
    let emptyVortrag;

    const emptyFilter = {
        datum: '',
        dirigent: '',
        result: '',
        liedid: ''
    }
    let filter = { ...emptyFilter };

    function oninit(vnode) {        
        ({ mpData } = vnode.attrs);
        vortraege = mpData.vortraege;
        emptyVortrag = new VortragInfo(mpData);
    }

    function handleFilterChange(e) {
        const fld = e.target.id.slice(7);
        filter[fld] = (fld == "dirigent") ? e.target.value.toUpperCase() : e.target.value;        
        m.redraw();
    }

    function handleFilterReset() {
        filter = { ...emptyFilter };
        m.redraw();
    }

    function handleRowClick(e) {
        goToSelectedId = 0;
        selectedId = parseInt(e.currentTarget.id.slice(4));
    }

    function handleNewVortrag() {
        selectedId = 0;
        handleEditVortrag();
    }

    function handleEditVortrag() {
        editVortrag = selectedId > 0 && vortraege.has(selectedId) ? /** @type {VortragInfo} */(vortraege.get(selectedId)) : emptyVortrag;
        showEditor = true;
        setTimeout(() => {
            const dialog = document.querySelector(editorId);
            /** @type {HTMLDialogElement} */(dialog)?.showModal();
        }, 10);
    }

    function handleDeleteVortrag() { 
        if (selectedId <= 0)
            return;
        showConfirm = true;
        setTimeout(() => {
            const dialog = document.querySelector(confirmId);
            /** @type {HTMLDialogElement} */(dialog)?.showModal();
        }, 10)
    }

    async function handleDeleteOk() {   
        /** @type {VortragInfo} */         
        const vortrag = /** @type {VortragInfo} */(vortraege.get(selectedId));
        const dialog = document.querySelector(confirmId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showConfirm = false;        
        await mpData.deleteVortrag(vortrag);
    }

    function handleDeleteCancel() {
        const dialog = document.querySelector(confirmId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showConfirm = false;
    }

    /**
     * @param {VortragInfo} vortrag
     * @returns Promise<void> 
     * @async
     */
    async function handleEditorSave(vortrag) {
        const dialog = document.querySelector(editorId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showEditor = false;
        await mpData.saveVortrag(vortrag, editVortrag);
        goToSelectedId = vortrag.id;
    }

    function handleEditorCancel() {
        const dialog = document.querySelector(editorId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showEditor = false;
    }

    /**
     * Normalize the displayed lied number.
     *
     * @param {string} dispLiedNr - The displayed lied number to be normalized.
     * @return {string} - The normalized lied number (all upper case, no spaces).
     */
    function normalizeDispLiedNr(dispLiedNr) {
        if (!dispLiedNr) return "";
        if (!dispLiedNr.match(/[A-Za-z]{2}[ ]?[0-9]{1,3}[a-i]*/g)) return "";
        return dispLiedNr.replace(/\s/g, '').toUpperCase();
    }

    /**
     * 
     * @returns {VortragInfo[]}
     */
    function getDispVortraege() {
        const normalizedFilterLiedNr = normalizeDispLiedNr(filter.liedid);
        return [...vortraege.values()].filter((vortrag) => {
            if (normalizedFilterLiedNr) {
                const dispLiedNr = mpData.getDispLiedNr(vortrag.liedid);
                if (!normalizeDispLiedNr(dispLiedNr).includes(normalizedFilterLiedNr)) {
                    return false;
                }
            } else if (filter.liedid) {
                if (!vortrag.getLied().text.includes(filter.liedid)) {
                    return false;
                }
            }
            if (filter.dirigent && !vortrag.dirigent.includes(filter.dirigent.toUpperCase())) {
                return false;
            }
            if (filter.datum) {
                const datum = mpData.getDispDate(vortrag.datum);
                if (!datum.includes(filter.datum)) {
                    return false;
                }
            }
            return true;
        });
    }

    function handleGoTop() {
      const divTableContainer = document.getElementById('table-container');
      if (divTableContainer) 
        divTableContainer.scrollTop = 0;      
    }

    function view(vnode) {
        ({ vortraege } = vnode.attrs.mpData);
        if (!vortraege) return null;
        const dispVortraege = getDispVortraege();
        if (goToSelectedId > 0 && goToSelectedId != selectedId) {
            selectedId = goToSelectedId;
            setTimeout(() => {                
                const el = document.getElementById(`row_${goToSelectedId}`);
                if (el) {
                    el.scrollIntoView();                    
                }
                goToSelectedId = 0;
            }, 100);
        }
        // @ts-ignore
        dispVortraege.sort((a, b) => {
            /** @type {number} */
            let res;
            switch (sort) {
                case 0:
                    res = b.datum.localeCompare(a.datum);
                    if (res == 0)
                        res = a.dirigent.localeCompare(b.dirigent);
                    if (res == 0) 
                        res == a.liedid - b.liedid;
                    return res;
                case 1:
                    res = a.dirigent.localeCompare(b.dirigent);
                    if (res == 0)
                        res = b.datum.localeCompare(a.datum); 
                    if (res == 0)
                        res == a.liedid - b.liedid;
                    return res;
                case 2:
                    return a.liedid - b.liedid;
            } 
        });
        let isSmall = window.screen.availWidth <= 800;
        return [
            m('h2', 'Vorträge'),
            m('button[type=button]#goTopBtn', { onclick: handleGoTop, title: 'Zum Anfang' }, m('i', { class: 'las la-angle-double-up' })),
            m('div#table-container', { style: 'overflow-y: scroll; height: 84vh; width:100%;' }, [
                m('table', { style: isSmall ? 'font-size:0.8em;' : 'font-size:0.95em;' }, [
                    m('thead', [
                        m('tr', [
                            m('th', { onclick: () => { sort = 0; }, }, [m('span', 'Datum'), sort == 0 && m('i.las.la-angle-down')]),
                            isSmall ? null : m('th', { onclick: () => { sort = 1; }, }, [m('span', 'Dirigent'), sort == 1 && m('i.las.la-angle-down')]),
                            m('th', { onclick: () => { sort = 2; }, }, [m('span', 'Lied'), sort == 2 && m('i.las.la-angle-down')]),
                            m('th', m('button.button.small', { onclick: handleNewVortrag }, 'Neuer Vortrag')),
                        ]),
                        m('tr', [
                            m(
                                'th.wd-6',
                                m('input[type=text]#filter_datum', {
                                    value: filter.datum,
                                    onchange: handleFilterChange,
                                })
                            ),
                            isSmall ? null : m(
                                'th.wd-6',
                                m('input[type=text]#filter_dirigent', {
                                    value: filter.dirigent,
                                    onchange: handleFilterChange,
                                })
                            ),
                            m(
                                'th.wd-24',
                                m('input[type=text]#filter_liedid', {
                                    value: filter.liedid,
                                    onchange: handleFilterChange,
                                })
                            ),
                            m(
                                'th',
                                m(
                                    'button.button.small',
                                    { title: 'Filter zurücksetzen', onclick: handleFilterReset },
                                    'X'
                                )
                            ),
                        ]),
                    ]),
                    m('tbody', [
                        Object.values(dispVortraege).map((vortrag) => {
                            const isSel = (vortrag.id === selectedId);
                            /** @type {LiedInfo} */
                            const lied = vortrag.getLied();
                            return m(
                                `tr#row_${vortrag.id}`,
                                // @ts-ignore
                                { class: isSel ? 'selected' : null, onclick: handleRowClick },
                                [
                                    m('td', mpData.getDispDate(vortrag.datum)),
                                    isSmall ? null : m('td', vortrag.dirigent),
                                    m('td.limit-text', lied.getDispLiedNr() + " - " + lied.text),
                                    m('td', { style: 'display: flex;' }, isSel
                                            ? [
                                                m('i.las.la-edit', { onclick: handleEditVortrag, title: 'Bearbeiten' }),
                                                m('i.las.la-trash-alt.ml-1', { onclick: handleDeleteVortrag, title: 'Löschen' }),
                                              ]
                                            : ''
                                    ),
                                ]
                            );
                        }),
                    ]),
                ]),
            ]),
            showEditor && m(VortragEditor, {
                mpData: vnode.attrs.mpData,
                editVortrag,
                onsave: handleEditorSave,
                oncancel: handleEditorCancel,
            }),
            showConfirm && m(ConfirmDialog, { 
                text: 'Sind Sie sicher, dass Sie den ausgewählten Vortrag löschen wollen?',
                onok: handleDeleteOk, 
                oncancel: handleDeleteCancel 
            }),
        ]; // return
    }

    return { oninit, view };
};

const VortragEditor = () => {
    let editVortrag = {};

    /** 
     * @callback SaveVortragCallback 
     * @param {VortragInfo} param1
     */
    /** @type {SaveVortragCallback} */
    let onsave;

    let oncancel;

    /** @typedef {{ id: number, datum: string, dispDatum: string, dispLiedNr: string, dispText: string,
     * sammlung: string, nummer: number, suffix: string, 
     * dirigent: string, liedid: number }} VortragModel */
    /** @type {VortragModel} */
    let model;

    /** @type {MappeData} */
    let mpData;

    let errors = {};

    function oninit(vnode) {
        ({ mpData, editVortrag, onsave, oncancel } = vnode.attrs);
        if (editVortrag) {
            const liedAttrs = mpData.decodeLiedId(editVortrag.liedid);
            const isNewVortrag = (editVortrag.id == 0);
            const lied = (editVortrag.liedid > 0 && mpData.lieder.has(editVortrag.liedid)) ? /** @type {LiedInfo} */(mpData.lieder.get(editVortrag.liedid)) : new LiedInfo(mpData);
            const lastVortragDatum = sessionStorage.mappe2024_lastVortragDatum ?? dayjs().format('YYYY-MM-DD');
            const datum = isNewVortrag ? lastVortragDatum : editVortrag.datum;
            const lastVortragDirigent = sessionStorage.mappe2024_lastVortragDirigent ?? '';
            model = {
                id: editVortrag.id,
                liedid: editVortrag.liedid,
                dirigent: isNewVortrag ? lastVortragDirigent : editVortrag.dirigent,
                datum,

                dispDatum: mpData.getDispDate(datum),
                dispLiedNr: lied.getDispLiedNr(),
                dispText: lied.text,
                sammlung: liedAttrs.sammlungKuerzel,
                nummer: liedAttrs.nummer,
                suffix: liedAttrs.suffix,                
            };
        }
    }

    /**
     * 
     * @param {VortragModel} model 
     * @returns {Object.<string, string>}
     */
    function validate(model) {
        /** @type {{dispDatum?: string, dispLiedNr?: string, sammlung?: string, dirigent?: string }} */
        const errors = {};

        if (!model.dispDatum) {
            errors.dispDatum = 'Datum fehlt';
        }

        if (!model.dispLiedNr || !model.dispText) {
            errors.dispLiedNr = 'Lied-Nr. fehlt oder ungültig';
        }
        
        if (!model.sammlung) {
            errors.sammlung = 'Sammlung fehlt';
        }

        if (!model.dirigent) {
            errors.dirigent = 'Dirigent fehlt';
        }
        return errors;
    }

    function handleSave() {
        errors = validate(model);
        if (Object.keys(errors).length > 0) {
            return;
        }        

        const data = {
            id: model.id,
            liedid: mpData.encodeLiedId({
                sammlungKuerzel: model.sammlung,
                nummer: model.nummer,
                suffix: model.suffix,
            }),            
            dirigent: model.dirigent,
            datum: mpData.getSqlDate(model.dispDatum)
        };
        if (model.id == 0) {
            sessionStorage.setItem('mappe2024_lastVortragDatum', data.datum);
            sessionStorage.setItem('mappe2024_lastVortragDirigent', data.dirigent);    
        }
        /** @type {VortragInfo} */
        const vortrag = new VortragInfo(mpData);
        vortrag.initialize(data);        
        onsave?.(vortrag);
    }

    function handleCancel() {
        oncancel?.();
    } 

    function handleDispLiedNrChange(e) {
        model.dispLiedNr = e.target.value.trim();
        delete errors.dispLiedNr;

        const regex = /^(?<sammlung>[A-Za-z][A-Za-z])*\s*(?<nummer>[0-9]{1,3}){1}(?<suffix>[a-z]*)/g;
        const m = regex.exec(model.dispLiedNr ?? '');
        if (m && m.groups) {
            model.sammlung = m.groups.sammlung.toUpperCase();
            model.nummer = parseInt(m.groups.nummer ?? '0');
            model.suffix = m.groups.suffix.toLowerCase();
        } else {
            errors.dispLiedNr = 'Ungültige Lied-Nr.';
            return;
        }
        model.liedid = mpData.encodeLiedId({
            sammlungKuerzel: model.sammlung,
            nummer: model.nummer,
            suffix: model.suffix
        });
        model.dispText = "";        
        model.dispLiedNr = "";
        if (model.liedid > 0 && mpData.lieder.has(model.liedid)) {
            /** @type {LiedInfo} */
            const lied = /** @type {LiedInfo} */(mpData.lieder.get(model.liedid));
            model.dispText = lied.text;
            model.dispLiedNr = lied.getDispLiedNr();
        }
    }

    /**
     * @param {{ attrs: { mpData: import("./types").MappeData; editVortrag: {}; onsave: any; oncancel: any; }; }} vnode
     */
    function view(vnode) {        
        const dirigentOptions = [...mpData.dirigenten.keys()].map((key) => {
            const dirigent = /** @type {DirigentInfo} */(mpData.dirigenten.get(key));
            return { value: dirigent.kuerzel, text: `${dirigent.kuerzel} - ${dirigent.text }` };
        });
        return m('dialog#vortrag-editor.wd-32',
            editVortrag && [
                m('h2', editVortrag.id ? 'Vortrag bearbeiten' : 'Neuen Vortrag erfassen'),

                m(InputText, { label: 'Datum', model, field: 'dispDatum', classes: { lblCol: 'col-4', inputCol: 'col-4' }, errors }),
                m(InputSelect, { label: 'Dirigent', model, field: 'dirigent', errors, classes: { lblCol: 'col-4', inputCol: 'col-8' }, options: dirigentOptions }),
                m(InputText,{ label: 'Lied-Nr.', model, field:'dispLiedNr', classes: { lblCol: 'col-4', inputCol: 'col-8' }, placeholder: 'CB 123a',
                    errors, onchange: handleDispLiedNrChange }),
                m('div.row', [
                    m('div.col-4', m('label', { for: 'dispText' }, 'Text')),
                    m('div.col-8', model.dispText)
                ]),
                              
                m(SaveCancel, { onsave: handleSave, oncancel: handleCancel })
            ] // editVortrag            
        );
    }

    return { oninit, view };
}

export { VortraegeView, VortragEditor };
