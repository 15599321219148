// @ts-check
import dayjs from 'dayjs';
import PdfGenReport from './pdfgen_report';

import MappeData from './mappe_data';
import SammlungInfo from './sammlung_info';
import LiedInfo from './lied_info';
import ProbeInfo from './probe_info';
import VortragInfo from './vortrag_info';

/** @typedef {import ("./types").ExtLiedInfo} ExtLiedInfo */

const ReportFactory = function() {
        
    /** @type PdfGenReport */
    let pdf;
    
    const MAPPE_TOKEN = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJiZWF0IiwiYXVkIjoiaHR0cDovL2RhdGFzdG9yZS53aWJlNDE1My5jaCIsInNjb3BlcyI6WyJ1c2VyIl0sImlzcyI6Imh0dHA6Ly93aWJlNDE1My5jaCIsImlhdCI6MTcwNjA4Njc1MiwibmJmIjoxNzA2MDg2NzUyLCJleHAiOjE3MDYxNzMxNTJ9.8InfMNIptpCFF41zTySaBYzkmucsYMPgWd8Peb2_lMI";

    const Template = {
        Vortraege: 1,
        Proben: 2,
        LiederNummern: 3,
        LiederAlpha: 4,
        LiederHits: 5
    }

    const wDatum = 36;
    const wLiedNr = 16;
    const wText = 72;
    const wStatus = 12;
    const wResult = 12;
    const wProbe = 36;
    const wVortrag = 36;
    const wDirigent = 16;

    /**
     * @param {PdfGenReport} pdf
     * @param {number} template
     */
    function addTableHeader(pdf, template)
    {
        switch (template)
        {
            case Template.Vortraege:
                pdf.cell(wDatum, 0, "Datum");
                pdf.cell(wDirigent, 0, "Dirigent");
                pdf.cell(wLiedNr, 0, "Lied-Nr.");
                pdf.cell(wText, 0, "Text");
                break;
            case Template.Proben:
                pdf.cell(wDatum, 0, "Datum");
                pdf.cell(wDirigent, 0, "Dirigent");
                pdf.cell(wLiedNr, 0, "Lied-Nr.");
                pdf.cell(wText, 0, "Text");
                pdf.cell(wResult, 0, "Ergebnis");
                break;
            default:
                pdf.cell(wLiedNr, 0, "Lied-Nr.");
                pdf.cell(wText, 0, "Text");
                pdf.cell(wStatus, 0, "Status");
                pdf.cell(wProbe, 0, "Letzte Probe");
                pdf.cell(wVortrag, 0, "Letzter Vortrag");
                break;
        }

        pdf.ln(7);
        pdf.line(10, 0, 200, 0, 0.3);
        pdf.resetFont();
    }

    /**
     * @param {PdfGenReport} pdf
     * @param {LiedInfo | ProbeInfo | VortragInfo} item
     * @param {number} template
     * @param {MappeData} mpData
     */
    function addTableFields(pdf, item, template, mpData)
    {
        switch (template)
        {
            case Template.Vortraege:
                /** @type {VortragInfo} */
                const v = /** @type {VortragInfo} */(item);
                pdf.cell(wDatum, 0, mpData.getDispDate(v.datum));
                pdf.cell(wDirigent, 0, v.dirigent);
                pdf.cell(wLiedNr, 0, mpData.getDispLiedNr(v.liedid));
                pdf.cell(wText, 0, v.getLied()?.text ?? "");
                break;
            case Template.Proben:
                /** @type {ProbeInfo} */
                const p = /** @type {ProbeInfo} */(item);
                pdf.cell(wDatum, 0, mpData.getDispDate(p.datum));
                pdf.cell(wDirigent, 0, p.dirigent);
                pdf.cell(wLiedNr, 0, mpData.getDispLiedNr(p.liedid));
                pdf.cell(wText, 0, p.getLied()?.text ?? "");;
                pdf.cell(wResult, 0, p.result);
                break;
            default: // Lieder
                /** @type {LiedInfo} */
                const l = /** @type {LiedInfo} */(item);
                pdf.cell(wLiedNr, 0, mpData.getDispLiedNr(l.id));
                pdf.cell(wText, 0, l.text);
                pdf.cell(wStatus, 0, l.status);
                /** @type {ProbeInfo} */
                const lastProbe = /** @type {ProbeInfo} */(l.getLastProbeInfo());
                pdf.cell(wProbe, 0, mpData.getDispDate(lastProbe.datum) + " " + lastProbe.dirigent);
                /** @type {VortragInfo} */
                const lastVortrag = /** @type {VortragInfo} */(l.getLastVortragInfo());
                pdf.cell(wProbe, 0, mpData.getDispDate(lastVortrag.datum) + " " + lastVortrag.dirigent);
                break;
        }
        pdf.ln(4);
    }

    /**
     * @param {string} repTitle
     * @param {any} items
     * @param {number} template
     * @param {MappeData} mpData
     */
    function createReport(repTitle, items, template = Template.LiederNummern, mpData)
    {        
        pdf = new PdfGenReport();

        const useHitsSeparator = (template == Template.LiederHits);
        const useAlphaSeparator = (template == Template.LiederAlpha);
        const useSammlgSeparator = (template == Template.LiederNummern);
        const useDatumSeparator = (template == Template.Vortraege || template == Template.Proben);

        let lastCount = 0;
        let lastAlpha = "*";
        let lastSammlg = "*";
        let lastDatum = "*";

        pdf.selectSection(pdf.Sections.FirstHeader);
        pdf.setFont("Arial", 14, "B");
        pdf.cell(0, 0, repTitle);
        pdf.ln(10);
        pdf.setFont("", 8, "B");
        addTableHeader(pdf, template);

        pdf.selectSection(pdf.Sections.Header);        
        pdf.resetFont();
        pdf.cell(0, 0, repTitle);
        pdf.setX(10);
        pdf.cell(190, 0, "Seite @PAGENO", "R");
        pdf.ln(8);
        pdf.setFont("", 8, "B");
        addTableHeader(pdf, template);

        pdf.selectSection(pdf.Sections.Footer);
        pdf.resetFont();
        
        const timestamp = dayjs().format('DD.MM.YYYY HH:mm:ss');
        pdf.cell(180, 0, `${timestamp}`, "R");

        // Document wird vor allen anderen Collections ausgeführt!
        pdf.selectSection(pdf.Sections.Document);
        pdf.init("P", "mm", "A4");
        pdf.setDefaultFont("Arial", 8);
        pdf.setData(repTitle, "Beat Widmer", "WinFormsBlazorMappe2024");
        pdf.resetFont();

        pdf.selectSection(pdf.Sections.Body);
        pdf.addPage("P");

        for (let item of items)
        {
            if (useDatumSeparator)
            {
                let datum = "*";
                let txt = "";
                if (template == Template.Vortraege)
                {
                    datum = /** @type {MappeData} */(mpData).getDispDate(item.datum);
                    txt = "Vortrag vom " + datum;
                }
                else if (template == Template.Proben)
                {
                    datum = /** @type {MappeData} */(mpData).getDispDate(item.datum);
                    txt = "Probe vom " + datum;
                }
                if (lastDatum != datum)
                {
                    pdf.ln(1.5);
                    pdf.setFont("", 11, "B");
                    pdf.cell(0, 0, txt);
                    pdf.ln(5);
                    lastDatum = datum;
                }
            }
            else if (useSammlgSeparator)
            {
                /** @type {LiedInfo} */
                const lied = item;
                const dispLiedNr = /** @type {MappeData} */(mpData).getDispLiedNr(lied.id);
                if (lastSammlg != dispLiedNr.substring(0, 2))
                {
                    pdf.ln(1.5);
                    pdf.setFont("", 11, "B");
                    const slg = dispLiedNr.substring(0, 2);
                
                    /** @type {SammlungInfo} */
                    const sammlung = /** @type {SammlungInfo} */(mpData.sammlungen.get(slg));
                    pdf.cell(0, 0, sammlung.kuerzel2 + " - " + sammlung.text);
                    pdf.ln(5);
                    
                    lastSammlg = slg;
                }
            }
            else if (useHitsSeparator)
            {                
                /** @type {ExtLiedInfo} */
                const lied = item;
                if (lastCount != lied.hits)
                {
                    if (lied.hits <= 3)
                    {
                        break; // Abbrechen, da zu wenige Hits
                    }
                    if (lastCount > 0)
                    {
                        pdf.ln(1.5);
                    }
                    pdf.setFont("", 11, "B");
                    pdf.cell(0, 0, `${lied.hits} Vorträge`);
                    pdf.ln(5);

                }
                lastCount = lied.hits;
            }
            else if (useAlphaSeparator)
            {
                /** @type {LiedInfo} */
                const lied = item;
                if (lastAlpha != lied.text[0])
                {
                    if (lastAlpha != "*")
                    {
                        pdf.ln(1.5);
                    }

                    pdf.setFont("", 11, "B");
                    pdf.cell(0, 0, lied.text[0]);
                    pdf.ln(5);
                }
                lastAlpha = lied.text[0];
            }

            pdf.resetFont();
            addTableFields(pdf, item, template, mpData);
        }
        pdf.selectSection(pdf.Sections.Document);        
        pdf.output("F", "mappe2024/" + repTitle + ".pdf");
        return pdf.getReport();
    }    

    return { createReport, Template }
}

export default ReportFactory;
