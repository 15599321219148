// @ts-check

import MappeData from "./mappe_data";
import ProbeInfo from "./probe_info";
import VortragInfo from "./vortrag_info";

/** @typedef {import("./types").LiedAttrs} LiedAttrs */

/** @class */
class LiedInfo {
    /** @type {number} */
    #id = 0;

    /** @type {string} */
    #text = "";

    /** @type {string} */
    #status = "";

    /** @type {number} */
    #lastprobe = 0;

    /** @type {number} */
    #lastvortrag = 0;

    /** @type {string} */
    #changes = "";

    /** @type {MappeData} */
    #mpData;

    /** @type {number} */
    #hits = 0; // JSON ignore

    constructor(/** @type {MappeData} */mpData) {
        this.#mpData = mpData;
    }

    initialize(/** @type {Object.<string, any>} */ jsonData) {
        this.#id = jsonData['id'] ?? 0;
        this.#text = jsonData['text'] ?? '';
        this.#status = jsonData['status'] ?? '';
        this.#lastprobe = jsonData['lastprobe'] ?? 0;
        this.#lastvortrag = jsonData['lastvortrag'] ?? 0;
        this.#changes = jsonData['changes'] ?? 0;
    }

    /**
     * @returns Object.<string, any>
     */
    getJsonData() {
        return {
            id: this.#id,
            text: this.#text,
            status: this.#status,
            lastprobe: this.#lastprobe,
            lastvortrag: this.#lastvortrag,
            changes: this.#changes
        };
    }

    get id() { return this.#id; }
    set id(val) { this.#id = val; }

    get text() { return this.#text; }
    set text(val) { this.#text = val; }

    get status() { return this.#status; }
    set status(val) { this.#text = val; }

    get lastprobe() { return this.#lastprobe; }
    set lastprobe(val) { this.#lastprobe = val; }

    get lastvortrag() { return this.#lastvortrag; }
    set lastvortrag(val) { this.#lastvortrag = val; }

    get changes() { return this.#changes; }
    set changes(val) { this.#changes = val; }

    // nur für Hitliste!
    get hits() { return this.#hits; }
    set hits(val) { this.#hits = val; }

    /** @returns {ProbeInfo} */
    getLastProbeInfo() {
        if (this.#lastprobe == 0 || !this.#mpData.proben.has(this.#lastprobe)) {
            return new ProbeInfo(this.#mpData);
        }
        return /** @type {ProbeInfo}) */(this.#mpData.proben.get(this.#lastprobe));
    }

    /** @returns {VortragInfo} */
    getLastVortragInfo() {
        if (this.#lastvortrag == 0 || !this.#mpData.vortraege.has(this.#lastvortrag)) {
            return new VortragInfo(this.#mpData);
        }
        return /** @type {VortragInfo}) */(this.#mpData.vortraege.get(this.#lastvortrag));
    }

    /**
     * @returns {LiedAttrs}
     */
    getLiedNrAttrs() {
        return this.#mpData.decodeLiedId(this.#id);
    }

    /** 
     * @returns {string}
     */
    getDispLiedNr() {
        return this.#mpData.getDispLiedNr(this.#id);
    }
}

export default LiedInfo;