// @ts-check
import m from 'mithril';
import dayjs from 'dayjs';

import GemeindeInfo from './gemeinde_info';
import SammlungInfo from './sammlung_info';
import DirigentInfo from './dirigent_info';
import LiedInfo from './lied_info';
import ProbeInfo from './probe_info';
import VortragInfo from './vortrag_info';

const dataVersion = "1.3";

/** @typedef {import("./types").LiedAttrs} LiedAttrs */

/** @type {MappeData} */
class MappeData {

    /** @type {MappeData} */
    static _instance;

    /** @type {GemeindeInfo} */
    gemeinde = new GemeindeInfo(this);

    /** @type {Map<string, SammlungInfo>} */
    sammlungen = new Map();

    /** @type {Map<string, DirigentInfo>} */
    dirigenten = new Map();

    /** @type {Map<number, LiedInfo>} */
    lieder = new Map();

    /** @type {Map<number, ProbeInfo>} */
    proben = new Map();

    /** @type {Map<number, VortragInfo>} */
    vortraege = new Map();

    /** @type {string} */
    storeToken = '';
    /** @type {string[]} */
    slgKuerzel = [];
    /** @type {string} */
    storeBaseUrl;    

    static getInstance() {
        if (!this._instance) {
            this._instance = new MappeData();
        }
        return this._instance;
    }

    /**
     * @param {string} baseUrl
     * @param {string} token
     * @param {Function} setMsgFunc
     */
    async loadData(baseUrl, token, setMsgFunc) {
        if (!token) {
            throw new Error('No token');
        }
        if (!setMsgFunc) {
            throw new Error('No setMsg Function given.');
        }
        if (!baseUrl) {
            throw new Error('No baseUrl given.');
        }
        this.storeBaseUrl = baseUrl;
        this.storeToken = token;
        this.setMsg = setMsgFunc;

        /** @type {MappeData} */        
        try {
            let data = await m.request(`${this.storeBaseUrl}data/mappe2024/all`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            // Import GemeindeInfo
            this.gemeinde = new GemeindeInfo(this);
            this.gemeinde.initialize(data.gemeinde);
            
            // Import Sammlungen
            this.sammlungen.clear();
            for (let key in data.sammlungen) {                
                const slg = new SammlungInfo(this);
                slg.initialize(data.sammlungen[key]);
                this.sammlungen.set(slg.kuerzel2, slg);
            }

            // Import Dirigenten
            this.dirigenten.clear();
            for (let key in data.dirigenten) {                
                const dir = new DirigentInfo(this);
                dir.initialize(data.dirigenten[key]);
                this.dirigenten.set(dir.kuerzel, dir);
            }

            // Import Lieder
            this.lieder.clear();
            for (let key in data.lieder) {                
                const lied = new LiedInfo(this);
                lied.initialize(data.lieder[key]);
                this.lieder.set(lied.id, lied);
            }
            
            // Import Proben
            this.proben.clear();
            for (let key in data.proben) {                
                const probe = new ProbeInfo(this);
                probe.initialize(data.proben[key]);
                this.proben.set(probe.id, probe);
            }

            // Import Vorträge
            this.vortraege.clear();
            for (let key in data.vortraege) {                
                const vortrag = new VortragInfo(this);
                vortrag.initialize(data.vortraege[key]);
                this.vortraege.set(vortrag.id, vortrag);
            }
            
            this.setSammlungenKuerzel();
            return this;
        } catch (error) {
            throw(error);
        }
    }

    setSammlungenKuerzel() {
        const slg = [...this.sammlungen.values()];
        slg.sort((a, b) => a.offset - b.offset);
        this.slgKuerzel = slg.map((s) => s.kuerzel2);
    }
    
    // Save-Funktionen 

    /** 
     * @param {LiedInfo} lied 
     * @param {LiedInfo} [original]
     * @returns {Promise<void>}
     */
    async saveLied(lied, original) {
        let oldId = 0;
        if (original) {
            if (JSON.stringify(lied.getJsonData()) === JSON.stringify(original.getJsonData())) {
                return;
            }        
            oldId = original.id;
        }        
        this.lieder.set(lied.id, lied);
        
        if (oldId > 0 && oldId !== lied.id) {
            for (const /** @type {ProbeInfo} */ probe of [ ...this.proben.values() ]) {
                if (probe.liedid === oldId) {
                    probe.liedid = lied.id;
                }
            }
            for (const vortrag of [ ...this.vortraege.values() ]) {
                if (vortrag.liedid === oldId) {
                    vortrag.liedid = lied.id;
                }
            }
            if (this.lieder.has(oldId)) {
                this.lieder.delete(oldId)
                ;
            }
        }
        await this.saveData();
    }

    /**
     * @param {ProbeInfo} probe
     * @param {ProbeInfo} [original]
     * @returns Promise<void>
     */
    async saveProbe(probe, original) {
        if (!probe.id || probe.id == 0) {
            const arr = [ ...this.proben.values() ];
            const maxId = Math.max(...arr.map(obj => obj.id));
            probe.id = maxId + 1; 
        } else if (original) {
            if (JSON.stringify(probe.getJsonData()) === JSON.stringify(original.getJsonData())) {
                return;
            }        
        }
        this.proben.set(probe.id, probe);

        if (!this.lieder.has(probe.liedid))
            return;
        /** @type {LiedInfo} */
        const lied = /** @type {LiedInfo} */(this.lieder.get(probe.liedid));        

        if (lied.lastprobe > 0) {
            const lastProbe = lied.getLastProbeInfo();
            if (probe.datum > lastProbe.datum) {
                lied.lastprobe = probe.id;
            }
        } else {
            lied.lastprobe = probe.id;
        }
        
        if (lied.status == 'U') {
            lied.status = 'N';
        }        

        await this.saveData();
    }

    /**
     * @param {VortragInfo} vortrag
     * @param {VortragInfo} original?
     * @returns Promise<void>
     * @async
     */
    async saveVortrag(vortrag, original) {
        if (!vortrag.id || vortrag.id == 0) {
            const arr = [ ...this.vortraege.values() ];
            const maxId = Math.max(...arr.map(obj => obj.id));
            vortrag.id = maxId + 1;
        } else if (original) {
            if (JSON.stringify(vortrag.getJsonData()) === JSON.stringify(original.getJsonData())) {
                return;
            }
        }
        this.vortraege.set(vortrag.id, vortrag);

        if (!this.lieder.has(vortrag.liedid))
            return;
        /** @type {LiedInfo} */
        const lied = /** @type {LiedInfo} */(this.lieder.get(vortrag.liedid));

        if (lied.lastvortrag > 0) {
            const lastVortrag = lied.getLastVortragInfo();
            if (vortrag.datum > lastVortrag.datum) {
                lied.lastvortrag = vortrag.id;
            }
        } else {
            lied.lastvortrag = vortrag.id;
        }

        if (lied.status == 'U' || lied.status == 'N') {
            lied.status = 'P';
        }

        await this.saveData();
    }

    /**
     * 
     * @param {SammlungInfo} sammlung 
     * @param {SammlungInfo} [original]]
     * @returns Promise<void>
     */
    async saveSammlung(sammlung, original) {
        if (!sammlung.kuerzel2) {
            return;
        }
        
        if (!sammlung.id || sammlung.id == 0) {
            let maxId = 0;
            for (let s of [...this.sammlungen.values()]) {
                maxId = (s.id > maxId) ? s.id : maxId;
            }
            sammlung.id = maxId + 1;
        } else {
            if (original) {
                if (JSON.stringify(sammlung.getJsonData()) === JSON.stringify(original.getJsonData())) {
                    return;
                }
                if (sammlung.kuerzel2 !== original.kuerzel2) {
                    this.sammlungen.delete(original.kuerzel2);
                }
            }
        }
        this.sammlungen.set(sammlung.kuerzel2, sammlung); // überschreibt alten Wert
        this.setSammlungenKuerzel();
        await this.saveData();
    }

    /**
     * @param {DirigentInfo} dirigent
     * @param {DirigentInfo} [original]
     * @returns Promise<void>
     */
    async saveDirigent(dirigent, original) {
        if (!dirigent.id || dirigent.id == 0) {
            let maxId = 0;
            for (let d of [...this.dirigenten.values()]) {
                maxId = (d.id > maxId) ? d.id : maxId;
            }
            dirigent.id = maxId + 1;
        } else {
            if (original) {
                if (JSON.stringify(dirigent.getJsonData()) === JSON.stringify(original.getJsonData())) {
                    return;
                }
                if (dirigent.kuerzel !== original.kuerzel) {
                    /** @type {ProbeInfo} */
                    let probe;
                    for (probe of [ ...this.proben.values() ]) {
                        if (probe.dirigent == original.kuerzel) {
                            probe.dirigent = dirigent.kuerzel;
                        }
                    }
                    /** @type {VortragInfo} */
                    let vortrag;
                    for (vortrag of [ ...this.vortraege.values() ]) {
                        if (vortrag.dirigent == original.kuerzel) {
                            vortrag.dirigent = dirigent.kuerzel;
                        }
                    }
                    this.dirigenten.delete(original.kuerzel);
                }
            }            
        }

        this.dirigenten.set(dirigent.kuerzel, dirigent);
        await this.saveData();
    }

    /**
     * @param {GemeindeInfo} gemeinde
     */
    async saveGemeinde(gemeinde) {        
        if (JSON.stringify(gemeinde.getJsonData()) === JSON.stringify(this.gemeinde.getJsonData())) {
            return;
        }
        
        this.gemeinde = gemeinde;
        await this.saveData();
    }

    async saveData() {
        try {
            this.gemeinde.timestamp = dayjs().format("YYYY-MM-DD HH:mm:ss");
            this.gemeinde.version = dataVersion;
            this.setMsg('Bitte warten, speichere Änderungen...', 'warning', 0);
            const token = this.storeToken;

            // Prepare Json-Data
            const data = { 
                gemeinde: this.gemeinde.getJsonData(),
                sammlungen: {},
                dirigenten: {},
                lieder: {},
                proben: {},
                vortraege: {}                
            }

            // Add sammlungen
            for (const [key, value] of this.sammlungen) {
                data.sammlungen[key] = value.getJsonData();
            };

            // Add dirigenten
            for (const [key, value] of this.dirigenten) {
                data.dirigenten[key] = value.getJsonData();
            };

            // Add lieder
            for (const [key, value] of this.lieder) {
                data.lieder[key] = value.getJsonData();
            };

            // Add proben
            for (const [key, value] of this.proben) {
                data.proben[key] = value.getJsonData();
            };

            // Add vortraege
            for (const [key, value] of this.vortraege) {
                data.vortraege[key] = value.getJsonData();
            }

            await m.request(`${this.storeBaseUrl}data/mappe2024/all`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: data,
            });
            this.setMsg('Die Änderungen wurden gespeichert.', 'success', 2);
            m.redraw();
        } catch (error) {
            throw(error);
        } 
    }

    // Delete-Funktionen

    /** 
     * @param {LiedInfo} lied
     */
    async deleteLied(lied) {
        this.lieder.delete(lied.id);
        
        const probeIds = [ ...this.proben.values() ]
            .filter((/** @type {ProbeInfo} */probe) => probe.liedid === lied.id)
            .map((/** @type {ProbeInfo} */probe) => probe.id);
        
        for (const probeId of probeIds) {
            this.proben.delete(probeId);
        }
        
        const vortrIds = [ ...this.vortraege.values() ]
            .filter((/** @type {VortragInfo} */vortrag) => vortrag.liedid === lied.id)
            .map((/** @type {VortragInfo} */vortrag) => vortrag.id);
        
        for (const vortrId of vortrIds) {
            this.vortraege.delete(vortrId);
        }
        
        await this.saveData();
    }

    /**
     * @param {ProbeInfo} probe
     * @returns {Promise<void>}
     * @async
     */
    async deleteProbe(probe) {
        const { id, liedid } = probe;
        this.proben.delete(id);
        if (this.lieder.has(liedid)) {
            /** @type {LiedInfo} */
            let lied = /** @type {LiedInfo} */(this.lieder.get(liedid));
            lied.lastprobe = 0;

            const arr = [ ...this.proben.values() ].filter((p) => p.liedid === liedid);
            if (arr.length > 0) {
                arr.sort((a, b) => a.datum.localeCompare(b.datum));
                lied.lastprobe = arr[0].id;
            }
        }
        await this.saveData();
    }

    /**
     * @param {VortragInfo} vortrag
     * @returns {Promise<void>}
     * @async
     */
    async deleteVortrag(vortrag) {
        const { id, liedid } = vortrag;
        this.vortraege.delete(id);
        if (this.lieder.has(liedid)) {
            /** @type LiedInfo */
            const lied = /** @type LiedInfo */(this.lieder.get(liedid));
            lied.lastvortrag = 0;
        
            const arr = [ ...this.vortraege.values() ].filter(p => p.liedid === liedid);        
            if (arr.length > 0) {
                arr.sort((a, b) => a.datum.localeCompare(b.datum));
                lied.lastvortrag = arr[0].id;
            }
        }
        await this.saveData();
    }

    /**
     * @param {DirigentInfo} dirigent 
     * @returns {Promise<void>}
     * @async
     */
    async deleteDirigent(dirigent) {
        this.dirigenten.delete(dirigent.kuerzel);
        await this.saveData();
    }


    // Info- und Formatierfunktionen
    
    /**
     * @param {string} sqlDate
     */
    getDispDate(sqlDate) {
        return sqlDate ? sqlDate.split('-').reverse().join('.') : '';
    }

    /**
     * @param {string} dispDate
     */
    getSqlDate(dispDate) {
        return dispDate ? dispDate.split('.').reverse().join('-') : '';
    }    

    /**
     * Decodes the liedId to retrieve attributes.
     *
     * @param {number} liedId - the liedId to be decoded
     * @return {LiedAttrs} the attributes decoded from the liedId
     */
    decodeLiedId(liedId)
    {
        /** @type {LiedAttrs} */
        const defaultAttrs = { sammlungKuerzel: "", nummer: 0, suffix: "" };
        if (liedId <= 0)
        {
            return defaultAttrs;
        }
        const code = `${liedId}`.padStart(5, '0');
        const slg = parseInt(code[0]);
        const sammlungKuerzel = this.slgKuerzel[slg];
        const nummer = parseInt(code.substring(1, 4));
        const suf = parseInt(code.substring(4));
        const suffix = " abcdefghi".slice(suf, suf + 1).trim();
        return { sammlungKuerzel, nummer, suffix };
    }
    
    /**
     * Encodes the lied ID based on the given attributes
     * @param {LiedAttrs} attrs - The attributes to encode the lied ID
     * @returns {number} - The encoded lied ID
     */
    encodeLiedId(attrs)
    {
        const s = this.slgKuerzel.indexOf(attrs.sammlungKuerzel);
        if (s == -1)
            return 0;
        let result = s * 10_000;
        result += 10 * attrs.nummer;
        const suf = " abcdefghi".indexOf(attrs.suffix);
        if (suf == -1) throw ("Unzulässiges Suffix in encodeLiedId");
        result += suf;
        return result;
    }

    /**
     * Retrieves the displayed lied number based on the provided liedId.
     *
     * @param {number} liedId - description of parameter
     * @return {string} description of return value
     */
    getDispLiedNr(liedId)
    {
        const { nummer, sammlungKuerzel, suffix } = this.decodeLiedId(liedId);
        if (nummer == 0) return "";
        const nr = `${nummer}`.padStart(3, ' ');
        return `${sammlungKuerzel} ${nr}${suffix}`;
    }

    /**
     * Retrieves the displayed text for the given liedId from mpData.
     *
     * @param {number} liedId - the encoded liedId
     * @param {MappeData} mpData - the MappeData instance
     * @return {string} the displayed text
     */
    getDispLiedText(liedId, mpData)
    {
        if (liedId == 0) {
            return "";
        }
        if (this.lieder.has(liedId)) {
            return /** @type {LiedInfo} */(this.lieder.get(liedId)).text ?? '';
        }
        return "*** Text nicht gefunden ***";
    }

    /**
     * Get the lastProbe attributes for the given liedId.
     *
     * @param {number} liedId - the encoded liedId
     * @return {ProbeInfo} the last Probe
     */
    getLastProbeAttrs(liedId) {
        /** @type {ProbeInfo} */
        let res = new ProbeInfo(this);        
        if (liedId == 0 || !this.lieder.has(liedId)) 
            return res;
        /** @type {LiedInfo} */
        const lied = /** @type {LiedInfo} */(this.lieder.get(liedId));
        if (lied.lastprobe == 0)
            return res;
        const probeId = lied.lastprobe;
        if (!this.proben.has(probeId))
            return res;
        return /** @type {ProbeInfo} */(this.proben.get(probeId));
    }

    /**
     * Get the attributes for the given probeId.
     *
     * @param {number} probeId - the probeId
     * @return {ProbeInfo} the attributes of the probe entry
     */
    getProbeAttrs(probeId) {
        const defaultAttrs = new ProbeInfo(this);
        if (!probeId || probeId == 0 || !this.proben.has(probeId))
            return defaultAttrs;
        return /** @type {ProbeInfo} */(this.proben.get(probeId));
    }

     /**
     * Get the lastVortrag attributes for the given lied
     *
     * @param {number} liedId - the encoded liedId
     * @return {VortragInfo} the lastVortrag
     */
    getLastVortragAttrs(liedId) {
        let res = new VortragInfo(this);
        if (!liedId || liedId == 0 || !this.lieder.has(liedId))
            return res;
        const lied = /** @type {LiedInfo} */(this.lieder.get(liedId));
        if (lied.lastvortrag == 0)
            return res;
        const vortrId = lied.lastvortrag;
        if (!this.vortraege.has(vortrId)) 
            return res;
        return /** @type {VortragInfo} */(this.vortraege.get(vortrId));
    }   
    
    /**
     * Get the attributes for the given vortragId.
     *
     * @param {number} vortragId - the vortragId
     * @return {VortragInfo} the vortrag entry
     */
    getVortragAttrs(vortragId) {
        const defaultAttrs = new VortragInfo(this);
        if (!vortragId || vortragId == 0 || !this.vortraege.has(vortragId))
            return defaultAttrs;
        return /** @type {VortragInfo} */(this.vortraege.get(vortragId));
    }
}

export default MappeData;

