// @ts-check

import MappeData from "./mappe_data";

/** @class */
class DirigentInfo {
    /** @type {number} */
    #id = 0;

    /** @type {string} */
    #kuerzel = "";

    /** @type {string} */
    #text = "";

    /** @type {boolean} */
    #active = true;

    /** @type {MappeData} */
    #mpData;

    constructor(/** @type {MappeData} */mpData) {
        this.#mpData = mpData;
    }

    initialize(/** @type {Object.<string, any>} */ jsonData) {
        this.#id = jsonData['id'] ?? 0;
        this.#kuerzel = jsonData['kuerzel'] ?? '';
        this.#text = jsonData['text'] ?? '';
        this.#active = jsonData['active'] ?? false;
    }

    /**
     * @returns Object.<string, any>
     */
    getJsonData() {
        return {
            id: this.#id,
            kuerzel: this.#kuerzel,
            text: this.#text,
            active: this.#active
        };
    }

    get id() { return this.#id; }
    set id(val) { this.#id = val; }

    get kuerzel() { return this.#kuerzel; }
    set kuerzel(val) { this.#kuerzel = val; }

    get text() { return this.#text; }
    set text(val) { this.#text = val; }

    get active() { return this.#active }
    set active(val) { this.#active = val; }
}

export default DirigentInfo;