// @ts-check
import m from 'mithril';
import { InputText, InputCheckbox, SaveCancel, ConfirmDialog } from './components';

import MappeData from './mappe_data';
import DirigentInfo from './dirigent_info';
import ProbeInfo from './probe_info';
import VortragInfo from './vortrag_info';

const DirigentenView = () => {
    /** @type {MappeData} */
    let mpData;

    /** @type {Function} */
    let setMsg;

    /** @type {Map<string, DirigentInfo>} */
    let dirigenten = new Map();

    /** @type {DirigentInfo} */
    let editDirigent;
    
    /** @type {DirigentInfo} */
    let emptyDirigent;

    let selectedId = 0;
    let goToSelectedId = 0;
    let sort = 0;    
    let showEditor = false;
    let showConfirm = false;

    const editorId = '#dirigent-editor';
    const confirmId = '#confirm-dialog';    

    const emptyFilter = {        
        kuerzel: '',
        text: ''            
    }
    let filter = { ...emptyFilter };

    function oninit(vnode) {        
        ({ mpData, setMsg } = vnode.attrs);
        dirigenten = mpData.dirigenten;
        emptyDirigent = new DirigentInfo(mpData);
    }

    /**
     * @param {{ target: { id: string; value: string; }; }} e
     */
    function handleFilterChange(e) {
        const fld = e.target.id.slice(7);
        filter[fld] = (fld == "kuerzel") ? e.target.value.toUpperCase() : e.target.value; 
        m.redraw();
    }

    function handleFilterReset() {
        filter = { ...emptyFilter };
        m.redraw();
    }

    /**
     * @param {{ currentTarget: { id: string; }; }} e
     */
    function handleRowClick(e) {
        goToSelectedId = 0;
        selectedId = parseInt(e.currentTarget.id.slice(4));
    }

    function handleNewDirigent() {
        selectedId = 0;
        handleEditDirigent();
    }

    function handleEditDirigent() {
        editDirigent = selectedId > 0 ? findDirigentById(selectedId) : new DirigentInfo(mpData);
        showEditor = true;
        setTimeout(() => {
            const dialog = document.querySelector(editorId);
            /** @type {HTMLDialogElement} */(dialog)?.showModal();
        }, 10);
    }

   /**
     * @param {DirigentInfo} dirigent
     * @returns {Promise<void>}
     * @async
     */
   async function handleEditorSave(dirigent) {        
        if (!dirigent.id || dirigent.id == 0) {
            const arr = [ ...mpData.dirigenten.values() ];
            const maxId = Math.max(...arr.map(obj => obj.id));
            dirigent.id = maxId + 1;
        }
        const dialog = document.querySelector(editorId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showEditor = false;
        await mpData.saveDirigent(dirigent, editDirigent);
        goToSelectedId = dirigent.id;
    }

    /**
     * @param {number} id
     * @returns {DirigentInfo}
     */
    function findDirigentById(id) {
        let fnd = [ ...mpData.dirigenten.values() ].filter((d) => d.id == id);
        if (fnd.length == 0) {
            throw new Error(`Dirigent mit Id=${id} nicht gefunden.`);
        }
        return fnd[0];
    }
    
    async function handleDeleteDirigent() {
        if (selectedId <= 0)
            return;
        /** @type {DirigentInfo} */
        let dirigent = findDirigentById(selectedId);

        // benützt?
        let cnt = 0;
        /** @type {ProbeInfo} */
        let probe;
        /** @type {VortragInfo} */
        let vortrag;
        for (probe of [ ...mpData.proben.values() ]) {
            if (probe.dirigent == dirigent.kuerzel) {
                cnt += 1;
            }
        }
        for (vortrag of [ ...mpData.vortraege.values() ]) {
            if (vortrag.dirigent == dirigent.kuerzel) {
                cnt += 1;
            }
        }
        // falls ja, abbrechen
        if (cnt > 0) {
            setMsg("Der ausgewählte Dirigent ist noch in Proben und/oder Vorträgen eingetragen. Sie können Ihn deshalb nicht löschen.", "error", 0);
            return;
        }

        showConfirm = true;
        setTimeout(() => {
            const dialog = document.querySelector(confirmId);
            /** @type {HTMLDialogElement} */(dialog)?.showModal();
        }, 10)
    }

    /**
     * @returns {Promise<void>}
     * @async
     */
    async function handleDeleteOk() {
        if (selectedId <= 0)
            return;
        showConfirm = false;        
        const dirigent = findDirigentById(selectedId);
        if (!dirigent.id || dirigent.id != selectedId) return;      
        await mpData.deleteDirigent(dirigent);
    }

    function handleDeleteCancel() {
        const dialog = document.querySelector(confirmId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showConfirm = false;
    }

    function handleEditorCancel() {
        const dialog = document.querySelector(editorId);
        /** @type {HTMLDialogElement} */(dialog)?.close();
        showEditor = false;
    }    

    function getDispDirigenten() {
        return [ ...mpData.dirigenten.values() ].filter((dirigent) => {
            if (filter.kuerzel && !dirigent.kuerzel.includes(filter.kuerzel.toUpperCase())) {
                return false;
            }
            if (filter.text && !dirigent.text.toUpperCase().includes(filter.text.toUpperCase())) {
                return false;
            }
            return true;
        });
    }

    function handleGoTop() {
      const divTableContainer = document.getElementById('table-container');
      if (divTableContainer) 
        divTableContainer.scrollTop = 0;      
    }

    /**
     * @param {{ attrs: { mpData: any; }; }} vnode
     */
    function view(vnode) {
        const mpData = vnode.attrs.mpData;
        ({ dirigenten } = mpData);
        if (!dirigenten) return null;
        const dispDirigenten = getDispDirigenten();
        if (goToSelectedId > 0 && goToSelectedId != selectedId) {
            selectedId = goToSelectedId;
            setTimeout(() => {                
                const el = document.getElementById(`row_${goToSelectedId}`);
                if (el) {
                    el.scrollIntoView();                    
                }
                goToSelectedId = 0;
            }, 100);
        }
        dispDirigenten.sort((a, b) => {
            switch (sort) {
                case 0:
                    return a.kuerzel.localeCompare(b.kuerzel);
                case 1:
                    return a.text.localeCompare(b.text);
                default:
                    return 1;
            }            
        });
        let isSmall = window.screen.availWidth <= 800;
        return [
            m('h2', 'Dirigenten'),
            m('button[type=button]#goTopBtn', { onclick: handleGoTop, title: 'Zum Anfang' }, m('i', { class: 'las la-angle-double-up' })),
            m('div#table-container', { style: 'overflow-y: scroll; height: 84vh; width:100%;' }, [
                m('table', { style: isSmall ? 'font-size:0.8em;' : 'font-size:0.95em;' }, [
                    m('thead', [
                        m('tr', [
                            m('th', { onclick: () => { sort = 0; }, }, [m('span', 'Kürzel'), sort == 0 && m('i.las.la-angle-down')]),
                            m('th', { onclick: () => { sort = 1; }, }, [m('span', 'Name'), sort == 1 && m('i.las.la-angle-down')]),
                            m('th', 'Aktiv'),
                            m('th', m('button.button.small', { onclick: handleNewDirigent }, 'Neuer Dirigent')),
                        ]),
                        m('tr', [
                            m(
                                'th.wd-6',
                                m('input[type=text]#filter_kuerzel', {
                                    value: filter.kuerzel,
                                    onchange: handleFilterChange,
                                })
                            ),
                            m(
                                'th.wd-24',
                                m('input[type=text]#filter_text', {
                                    value: filter.text,
                                    onchange: handleFilterChange,
                                })
                            ),
                            m('th.wd-4'),
                            m('th', 
                                m('button.button.small', { title: 'Filter zurücksetzen', onclick: handleFilterReset }, 'X')
                            ),
                        ]),
                    ]),
                    m('tbody', [
                        Object.values(dispDirigenten).map((dirigent) => {
                            const isSel = dirigent.id === selectedId;
                            return m(
                                `tr#row_${dirigent.id}`,
                                // @ts-ignore
                                { class: isSel ? 'selected' : null, onclick: handleRowClick },
                                [
                                    m('td', dirigent.kuerzel),  
                                    m('td', dirigent.text ?? ''),
                                    m('td', dirigent.active ? 'Ja' : 'Nein'),
                                    m('td', { style: 'display:flex;' }, isSel ? [ 
                                        m('i.las.la-edit', { onclick: handleEditDirigent, title: 'Bearbeiten' }),
                                        m('i.las.la-trash-alt.ml-1', { onclick: handleDeleteDirigent, title: 'Löschen' })
                                    ] : '') 
                                ]
                            );
                        }),
                    ]),
                ]),
            ]),
            showEditor && m(DirigentEditor, {
                    mpData: vnode.attrs.mpData,
                    editDirigent,
                    onsave: handleEditorSave,
                    oncancel: handleEditorCancel,
                }),
            showConfirm && m(ConfirmDialog, {
                text: "Sind Sie sicher, dass Sie diesen Dirigenten löschen wollen?",
                onok: handleDeleteOk,
                oncancel: handleDeleteCancel
            })
        ]; // return
    }

    return { oninit, view };
};

const DirigentEditor = () => {
    /** @type {DirigentInfo} */
    let editDirigent;

    /** 
     * @callback SaveDirigentCallback 
     * @param {DirigentInfo} param1
     */
    /** @type {SaveDirigentCallback} */
    let onsave;
    let oncancel;

    /** @typedef {{ id: number, kuerzel: string, text: string, active: boolean }} DirigentModel */
    /** @type DirigentModel */
    let model;

    /** @type MappeData */
    let mpData;

    let errors = {};

    let isNew = true;

    /**
     * @param {{ attrs: { mpData: MappeData; editDirigent: DirigentInfo; onsave: SaveDirigentCallback; oncancel: Function; }; }} vnode
     */
    function oninit(vnode) {
        ({ mpData, editDirigent, onsave, oncancel } = vnode.attrs);
        isNew = true;
    }

    /**
     * @param {DirigentModel} model
     */
    function validate(model) {
        /** @type {{ kuerzel?: string; text?: string; }} */
        const errors = {};
        if (!model.kuerzel) {
            errors.kuerzel = 'Kürzel fehlt';
        } else if (model.kuerzel.length != 2) {
            errors.kuerzel = 'Genau 2 Zeichen';
        } else if (model.kuerzel.toUpperCase() != model.kuerzel) {
            errors.kuerzel = '2 Grossbuchstaben!';
        } else if (model.id == 0) { // Neuer Datensatz
            if ([ ...mpData.dirigenten.values() ].map(dirigent => dirigent.kuerzel).indexOf(model.kuerzel) >= 0) {
                errors.kuerzel = 'Kürzel bereits vergeben';
            }
        }

        if (!model.text) {
            errors.text = 'Name fehlt';
        }
                
        return errors;
    }

    function handleSave() {
        errors = validate(model);
        if (Object.keys(errors).length > 0) {
            return;
        }        
        /** @type DirigentInfo */
        const dirigent = new DirigentInfo(mpData);

        /** @type {{ id: number; kuerzel: string; text: string; active: boolean }} */
        dirigent.initialize({
            id: model.id,
            kuerzel: model.kuerzel,
            text: model.text,
            active: model.active
        });
        onsave?.(dirigent);
        isNew = true;
    }

    function handleCancel() {
        oncancel?.();
        isNew = true;
    } 

    /**
     * @param {{ attrs: { mpData: any; editDirigent: DirigentInfo; onsave: any; oncancel: Function; }; }} vnode
     */
    function view(vnode) {
        ({ mpData, editDirigent, onsave, oncancel } = vnode.attrs);
        if (editDirigent && isNew) {
            model = {
                id: editDirigent.id,
                kuerzel: editDirigent.kuerzel,
                text: editDirigent.text,
                active: editDirigent.active,
            };
            isNew = false;           
        }
        return m('dialog#dirigent-editor.wd-32',
            editDirigent && [
                m('h2', editDirigent.id ? 'Dirigent bearbeiten' : 'Neuen Dirigenten erfassen'),

                m(InputText, { label: 'Kürzel', model, field: 'kuerzel', errors, classes: { lblCol: 'col-4', inputCol: 'col-4' },
                    onchange: (/** @type {{ target: { value: string; }; }} */ e) => {
                        model.kuerzel = e.target.value.toUpperCase();
                    }
                }),                    
                m(InputText, { label: 'Name', model, field: 'text', errors, classes: { lblCol: 'col-4', inputCol: 'col-8' }}),
                m(InputCheckbox, { label: 'Aktiv', model, field: 'active', errors, classes: { lblCol: 'col-4', inputCol: 'col-2' }}),
                
                m(SaveCancel, { onsave: handleSave, oncancel: handleCancel }),                
            ] // editDirigent            
        );
    }

    return { oninit, view };
}

export { DirigentenView, DirigentEditor };
