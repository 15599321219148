import m from 'mithril';

/*
function InputText() {
    function oninit(vnode) {

    }

    function view(vnode) {

    }

    return { oninit, view };
}
*/

/**
 * Erstellt ein Input-Element als Mithril-Komponente
 * @returns any
 * Attributes:
 * - label
 * - field
 * - model
 * - classes
 * - placeholder
 * - errors
 * - onchange
 */
function InputText() {
    let label = "";
    let field = "";
    let model = {};
    let placeholder;
    let classes;    
    let errors = {};
    let onchange;

    function oninit(vnode) {
        ({ label, field, model, errors, classes, placeholder, onchange, oninput } = vnode.attrs);
        if (!classes) classes = {};
        if (!classes.lblCol) classes.lblCol = 'col-2';
        if (!classes.inputCol) classes.inputCol = 'col-10';
    }

    function handleChange(e) {
        if (onchange) 
            onchange(e);
        else {
            if (model[field] !== e.target.value) {
                delete errors[field];
                model[field] = e.target.value;
            }            
        }                
    }

    function view(vnode) {
        ({ label, field, model, errors } = vnode.attrs);
        return m('div.row', [
            m('div', { class: classes.lblCol }, [
                m('label', { class: classes.label, for: field }, label)
            ]),
            m('div.validated-field', { class: classes.inputCol }, [
                m('input[type=text]#' + field, { class: classes.input,
                    value: model[field] ?? '', spellcheck: false, placeholder: placeholder, onchange: handleChange
                }),
                m('p.field-error', errors[field])
            ]),            
        ])
    }

    return { oninit, view };
}

/**
 * Erstellt ein Input-Element mit Type=Number als Mithril-Komponente
 * @returns any
 * Attributes:
 * - label
 * - field
 * - model
 * - classes
 * - errors
 * - onchange
 */
function InputNumber() {
    let label = "";
    let field = "";
    let model = {};
    let classes;    
    let errors = {};
    let onchange;

    function oninit(vnode) {
        ({ label, field, model, errors, classes, onchange } = vnode.attrs);
        if (!classes) classes = {};
        if (!classes.lblCol) classes.lblCol = 'col-4';
        if (!classes.inputCol) classes.inputCol = 'col-8';
    }

    function handleChange(e) {
        if (onchange)
            onchange(e)
        else {
            const value = parseInt(e.target.value ?? '0');
            model[field] = value;
        }        
        delete errors[field];
    }

    function view(vnode) {
        ({ label, field, model, errors } = vnode.attrs);
        return m('div.row', [
            m('div', { class: classes.lblCol }, [
                m('label', { class: classes.label, for: field }, label)
            ]),
            m('div.validated-field', { class: classes.inputCol }, [
                m('input[type=number]#' + field, { class: classes.input,
                    value: model[field] ?? '', spellcheck: false, onchange: handleChange
                }),
                m('p.field-error', errors[field])
            ]),            
        ])
    }

    return { oninit, view };
}

/**
 * Erstellt ein Input-Element mit Type=Password als Mithril-Komponente
 * @returns any
 * Attributes:
 * - label
 * - field
 * - model
 * - classes
 * - errors
 * - onchange
 */
function InputPassword() {
    let label = "";
    let field = "";
    let model = {};
    let classes;    
    let errors = {};
    let onchange;

    function oninit(vnode) {
        ({ label, field, model, errors, classes, onchange } = vnode.attrs);
        if (!classes) classes = {};
        if (!classes.lblCol) classes.lblCol = 'col-4';
        if (!classes.inputCol) classes.inputCol = 'col-8';
    }

    function handleChange(e) {
        if (onchange) {
            onchange(e);
        } else {
            model[field] = e.target.value;
            delete errors[field];
        }
    }

    function view(vnode) {
        ({ label, field, model, errors } = vnode.attrs);
        return m('div.row', [
            m('div', { class: classes.lblCol }, [
                m('label', { class: classes.label, for: field }, label)
            ]),
            m('div.validated-field', { class: classes.inputCol }, [
                m('input[type=password]#' + field, { class: classes.input,
                    value: model[field] ?? '', spellcheck: false, onchange: handleChange
                }),
                m('p.field-error', errors[field])
            ]),            
        ])
    }

    return { oninit, view };
}

/**
 * Erstellt ein Select-Element als Mithril-Komponente
 * @returns any
 */
function InputSelect() 
{
    /* Attributes
        - label
        - field
        - model
        - classes
        - onchange
        - options (Object[])
        - optValueField: string
        - optTextField: string,
        - errors        
    */
    let label = '';
    let field = '';
    let model = {};
    let onchange;
    let options = {};
    let optValueField;
    let optTextField;
    let errors = {};
    let classes;

    function oninit(vnode) {
        ({ model, field, classes, options, errors, oninput, onchange } = vnode.attrs);
        optValueField = vnode.attrs.optValueField ?? 'value';
        optTextField = vnode.attrs.optTextField ?? 'text';
        if (!classes) classes = {};
        if (!classes.lblCol) classes.lblCol = 'col-4';
        if (!classes.inputCol) classes.inputCol = 'col-8';
    }

    function handleChange(e) {
        if (onchange) {
            onchange(e);
        } else {
            model[field] = e.target.value;
            delete errors[field];
        }
    }

    function view(vnode) {
        ({ model, field, label, errors } = vnode.attrs);        
        if (!errors) errors = {};

        return m('div.row', [
            m('div', { class: classes.lblCol }, [
                m('label', { class: classes.label, for: field }, label)
            ]),
            m('div.validated-field', { class: classes.inputCol }, [
                m('select#' + field, { class: classes.input,
                    value: model[field] ?? '', spellcheck: false, oninput: (e) => (model[field] = e.target.value), onchange: handleChange
                }, options.map((opt) => {
                    const sel = (model[field] == (opt[optValueField] ?? ''));
                    return m('option', { value: opt[optValueField] ?? '', selected: sel }, opt[optTextField]);
                })),
                m('p.field-error', errors[field])
            ]),            
        ])
    }

    return { oninit, view };
}


/**
 * Erstellt ein Checkbox-Element als Mithril-Komponente
 * @returns any
 */
function InputCheckbox() {
    /* Attributes
        - label
        - field
        - model
        - onchange
        - classes
        - errors
    */
    let label;
    let field = '';
    let model = {};
    let classes;
    let errors;
    let onchange = null;    

    function oninit(vnode) {
        ({ label, model, field, classes, errors, onchange } = vnode.attrs);
        if (!errors) errors = {};
        if (!classes) classes = {};
        if (!classes.lblCol) classes.lblCol = 'col-4';
        if (!classes.inputCol) classes.inputCol = 'col-8';
    }
        
    function handleChange(e) {
        if (onchange) {
            onchange(e);
        } else {
            model[field] = e.target.checked;
            delete errors[field];
        }
    }

    function view(vnode) {
        ({ model, field, errors } = vnode.attrs);
        if (!errors) errors = {};
        if (!classes) classes = {};        
        
        return m('div.row', [
            m('div', { class: classes.lblCol }, [
                m('label', { class: classes.label, for: field }, label)
            ]),
            m('div.validated-field', { class: classes.inputCol }, [
                m('input[type=checkbox]#' + field, {
                    checked: model[field] ?? false, onchange: handleChange
                }),
                m('p.field-error', errors[field])
            ]),            
        ])
    }

    return { oninit, view };
}


// function Textarea() {
//     /* Attributes
//         - label
//         - field
//         - model
//         - oninput    
//         - onchange            
//         - rows (opt, default=3)
//         - class (opt, default=w32)
//         - errors
//         - required (opt, default=false)
//     */

//     let field = '';
//     let model = {};
//     let oninput = null;
//     let onchange = null;

//     function oninit(vnode) {
//         ({ model, field, oninput, onchange } = vnode.attrs);
//     }

//     function handleInput(e) {
//         if (oninput) {
//             oninput(e);
//         } else {
//             model[field] = e.target.value;
//         }
//     }

//     function handleChange(e) {
//         if (onchange) {
//             onchange(e);
//         }
//     }

//     function view(vnode) {
//         model = vnode.attrs.model;
//         field = vnode.attrs.field;
//         let cls = vnode.attrs.class ?? 'w32';
//         let errors = vnode.attrs.errors ?? [];
//         let req = vnode.attrs.required ?? false;
//         let rows = vnode.attrs.rows ?? 3;
//         if (Array.isArray(cls)) {
//             cls = cls.join('.');
//         }
//         return m('div.field', [
//             m('label.mr-05em', { for: field, required: true }, vnode.attrs.label),
//             m('textarea#' + field + '.' + cls, {
//                 value: model[field] ?? '', spellcheck: false, rows, required: req,
//                 oninput: handleInput, onchange: handleChange
//             }),
//             errors[field] && m('br'),
//             errors[field] && m('p.field-error', errors[field])
//         ]);
//     }

//     return { oninit, view };
// }



// function Canvas() {
//     let lines = [];
//     let id;
//     let width;
//     let height;

//     function oninit(vnode) {
//         ({ id, lines, width, height } = vnode.attrs);
//     }

//     function MM2PX(w)
//     {
//         return 9.6 / 2.54 * w;
//     }
    
//     function oncreate(vnode) {
//         let canvas = document.querySelector('#' + id);
//         if (canvas) {
//             const ctx = canvas.getContext('2d');
//             for (let i = 0; i < lines.length; i += 1) {
//                 let item = lines[i];
//                 let x1 = MM2PX(parseFloat(item.x1));
//                 let y1 = MM2PX(parseFloat(item.y1));
//                 let x2 = MM2PX(parseFloat(item.x2));
//                 let y2 = MM2PX(parseFloat(item.y2));
//                 let wd = parseFloat(item.wd);
//                 if (x1 == x2 || y1 == y2) {
//                     x1 -= 4;
//                     x2 -= 4;
//                 }
//                 ctx.fillStyle = 'black';
//                 ctx.beginPath();
//                 ctx.lineWidth = wd;
//                 ctx.moveTo(x1, y1);
//                 ctx.lineTo(x2, y2);
//                 ctx.closePath();
//                 ctx.stroke();
//             }
//         }
//     }

//     function view(vnode) {
//         // let style = 'left:0; top:0;position:absolute;';
//         return(m('canvas', { id, width: MM2PX(width), height: MM2PX(height) }));
//     }

//     return { oninit, oncreate, view };
// } 

// export { Input, Password, Textarea, Checkbox, Select, Canvas };

function SaveCancel() {
    let onsave;
    let oncancel;
    let classes = {};
    let labels = {};    

    function oninit(vnode) {
        ({ onsave, oncancel, classes, labels } = vnode.attrs);
        if (!labels) labels = {};
        if (!classes) classes = {};
        if (!labels.save) labels.save = 'Speichern';
        if (!labels.cancel) labels.cancel = 'Abbrechen';    
    }

    function view() {  
        const isSmall = window.screen.availWidth <= 800;
        const cls = isSmall ? 'wd-50' : 'col-6';    
        return m('div.row', [
            onsave && m(`div.${cls}.center-content`, { style: isSmall ? 'width:50%' : null, class: classes.saveCol ?? null },
                m('button[type=button].button.primary', { class: classes.btnSave, onclick: onsave }, labels.save)
            ),
            oncancel && m(`div.${cls}.center-content`, { style: isSmall ? 'width:50%' : null, class: classes.cancelCol ?? null },
                m('button[type=button].button.primary', { class: classes.btnCancel, onclick: oncancel }, labels.cancel)
            ),
        ]);
    }

    return { oninit, view };
}

const ConfirmDialog = () => {
    function view(vnode) {
        const { text, onok, oncancel } = vnode.attrs;
        return m('dialog#confirm-dialog.wd-32', [
            m('div.row', m('div.col-12', text)),
            m('div.row', [                
                onok && m('div.col-6.center-content', 
                    m('button[type=button].button.primary', { onclick: onok }, "Ja")
                ),
                oncancel && m('div.col-6.center-content', 
                    m('button[type=button].button.primary', { onclick: oncancel }, "Nein")
                ),
            ]),
        ]);
    }

    return { view };
}

export { InputText, InputNumber, InputPassword, InputSelect, InputCheckbox, SaveCancel, ConfirmDialog };

