// @ts-check
import m from 'mithril';

const LoginView = () => {
    let username = '';
    let password = '';
    let onsuccess;
    
    /** @type {Function} */
    let setMsg;
    let baseurl;

    function oninit(vnode) {
        ({ baseurl, onsuccess, setMsg } = vnode.attrs);
    }

    async function handleLogin(e) {
        try {
            e.preventDefault();
            const response = await m.request(`${baseurl}login`, {
                method: 'POST',
                body: { userid: username, password, application: 'mappe2023' },
            });
            onsuccess?.(response);
        } catch (error) {
            setMsg('Fehler bei der Anmeldung', error, 0);
        }
    }

    function handleReset() {
        username = '';
        password = '';
        const fld = document.querySelector('#username');
        if (fld) {
            // @ts-ignore
            fld.focus();
        }
    }

    function view() {
        return m('div.row', { style: 'margin-top: 8em; max-width: 52em;' }, [
            m('div.col-4'),
            m('form.col-4', { onsubmit: handleLogin }, [
                m('div.row', m('h3', 'Mappe 2024 - Anmeldung')),
                m('div.row', [
                    m('label', 'Benutzername'),
                    m('input[type=text]#username', {
                        value: username,
                        autofocus: true,
                        onchange: (e) => {
                            username = e.target.value;
                        },
                    }),
                    m('label', 'Passwort'),
                    m('input[type=password]#password', {
                        value: password,
                        onchange: (e) => {
                            password = e.target.value;
                        },
                    }),
                    // m('p', `width x height =${window.innerWidth} x ${window.innerHeight}`),
                    // m('p', `screen.availWidth x height =${window.screen.availWidth} x ${window.screen.availHeight}`),
                    // m('p', `devicePixelRatio = ${window.devicePixelRatio}`),
                    m('div.row.mt-2', [
                        m(
                            'div.col.col-6-lg.is-center',
                            m('button[type=submit].button.primary', { onclick: handleLogin }, 'Anmelden')
                        ),
                        m(
                            'div.col.col-6-lg.is-center',
                            m('button[type=reset].button.secondary', { onclick: handleReset }, 'Zurücksetzen')
                        ),
                    ]),
                ]),
            ]),
        ]);
    }

    return { oninit, view };
};

export { LoginView };