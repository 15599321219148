// @ts-check
import m from 'mithril';
import { InputText, InputNumber, InputSelect, InputCheckbox, SaveCancel } from './components';

import MappeData from './mappe_data';
import GemeindeInfo from './gemeinde_info';


class GemeindeView 
{
    /** @type {MappeData} */
    mpData;

    /** @type {Object.<string, string>} */
    errors = {};

    /** @type {Object.<string, any>} */
    model;

    /** @type {GemeindeInfo} */
    gemeinde;

    /**
     * @param {{ attrs: { mpData: MappeData; }; }} vnode
     */
    constructor(vnode) {
        this.mpData = vnode.attrs.mpData;
        this.gemeinde = this.mpData.gemeinde;
        if (this.gemeinde) {
            this.model = this.gemeinde.getJsonData();  
        }
    }

    /**
     * @param {Object.<string, any>} model
     * @returns {Object.<string, string>}
     */
    validate(model) {
        /** @type {Object.<string, string>} */
        const errors = {};
        
        if (!model.name) {
            errors.name = 'Name der Gemeinde fehlt';
        }
        if (this.gemeinde.sperrfrist == 0) {
            errors.gemeinde = 'Angabe fehlt'
        }
        if (this.gemeinde.pseitprobe == 0) {
            errors.pseitprobe = 'Angabe fehlt'
        }
        if (this.gemeinde.rprobeintervall == 0) {
            errors.rprobeintervall = 'Angabe fehlt'
        }
        if (this.gemeinde.wtprobe == 0) {
            errors.wtprobe = 'Angabe fehlt'
        }
        if (this.gemeinde.wtgd == 0) {
            errors.wtgd = 'Angabe fehlt'
        }

        if (Object.keys(errors).length > 0) {
            return errors;
        }
        return {};
    }

    handleSave = async() => {
        this.errors = this.validate(this.model);
        if (Object.keys(this.errors).length > 0) {
            return;
        }        
        const gemeinde = new GemeindeInfo(this.mpData);
        gemeinde.initialize(this.model);
        await this.mpData.saveGemeinde(gemeinde);
    }

    /**
     * @param {{ attrs: { id: any; }; }} vnode
     */
    view(vnode) {    
        const id = vnode.attrs.id;    
        const wtOptions = [ 
            { value: 0, text: 'Sonntag'},
            { value: 1, text: 'Montag'},
            { value: 2, text: 'Dienstag'},
            { value: 3, text: 'Mittwoch'},
            { value: 4, text: 'Donnerstag'},
            { value: 5, text: 'Freitag'},
            { value: 6, text: 'Samstag' },
        ];
        return [
            m('h2', 'Gemeinde'),
                m('div.dialog', { id }, [
                    m(InputText, { label: 'Gemeinde-Name', field: 'name', model: this.model, classes: { lblCol: 'col-3', inputCol: 'col-4', input: 'wmx-12' }, errors: this.errors }),
                    m(InputNumber, { label: 'Sperrfrist nach Vortrag', field: 'sperrfrist', model: this.model, classes: { lblCol: 'col-3', inputCol: 'col-2', input: 'wmx-6' }, errors: this.errors }),
                    m(InputNumber, { label: 'Vortrag nach Probe (P-Lieder)', field: 'pseitprobe', model: this.model, classes: { lblCol: 'col-3', inputCol: 'col-2', input: 'wmx-6' }, errors: this.errors }),
                    m(InputNumber, { label: 'Probe-Intervall (R-Lieder)', field: 'rprobeintervall', model: this.model, classes: { lblCol: 'col-3', inputCol: 'col-2', input: 'wmx-6' }, errors: this.errors }),
                    m(InputSelect, { label: 'Wochentag Probe', field: 'wtprobe', model: this.model, classes: { lblCol: 'col-3', inputCol: 'col-2', input: 'wmx-10' }, errors: this.errors, options: wtOptions}),
                    m(InputSelect, { label: 'Wochentag GD (3=Mi)', field: 'wtgd', model: this.model, classes: { lblCol: 'col-3', inputCol: 'col-2', input: 'wmx-10' }, errors: this.errors, options: wtOptions }),
                    m(InputCheckbox, { label: 'S-Lieder anzeigen', field: 'sshow', model: this.model, classes: { lblCol: 'col-3', inputCol: 'col-2' }, errors: this.errors }),

                    m(SaveCancel, { onsave: this.handleSave })
                ])
        ];
    }
    // {"gemeinde":"Reinach","sperrfrist":30,"pseitprobe":30,"rprobeintervall":180,"sshow":true,"dirigenten":"SH,YL,BA,BW","wtprobe":3,"wtgd":4}
}

export { GemeindeView }
