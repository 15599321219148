import m from 'mithril';

const MainMenu = () => {

    let onclick;
    let viewIndex = 0;
    let showBars;

    /**
     * @param {{ attrs: { viewIndex: number; showBars: any; onclick: any; }; }} vnode
     */
    function oninit(vnode) {
        ({ viewIndex, showBars, onclick } = vnode.attrs);
    }

    /**
     * @param {{ attrs: { viewIndex: number; showBars: any; }; }} vnode
     */
    function view(vnode) {
        ({ viewIndex, showBars } = vnode.attrs);

        return m('div.topnav#myTopnav' + (showBars ? ".topnav" : ".responsive"), { onclick: onclick }, [
            m('a#restart.logo', { href: "#", title: 'Abmelden' }, 'Mappe 2024'),
            m('a#lnk0', { href: "#", class: viewIndex == 0 ? "active" : null }, 'Listen'),
            m('a#lnk1', { href: "#", class: viewIndex == 1 ? "active" : null }, 'Lieder'),
            m('a#lnk2', { href: "#", class: viewIndex == 2 ? "active" : null }, 'Proben'),
            m('a#lnk3', { href: "#", class: viewIndex == 3 ? "active" : null }, 'Vorträge'),
            m('a#lnk4', { href: "#", class: viewIndex == 4 ? "active" : null }, 'Sammlungen'),
            m('a#lnk5', { href: "#", class: viewIndex == 5 ? "active" : null }, 'Dirigenten'),
            m('a#lnk6', { href: "#", class: viewIndex == 6 ? "active" : null }, 'Gemeinde'),
            m('a', { href: "#", class: "icon", title: "Menu umschalten", style: 'padding: 0;' },
                m('i#bars', { class: "la la-bars", style: 'padding: 0.5em; font-size: 1.5em;' })
            )            
        ]);
    }

    return { oninit, view };
}

export { MainMenu};